/* Start Common Css */
:root {
  --gray: rgba(255, 255, 255, 0.2);
  --white: #ffffff;
  --bgLightRed: #ff5c5d;
  --black: #000000;
}

.heading16 {
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
  font-weight: 400;
}

.headingGray32 {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: var(--gray);
}

.heading48 {
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
  color: var(--white);
}

/* End Common Css */
/* Start Schedule a Demo Page Css */
.scheduleDemoSection {
  padding-bottom: 72px;
}

.storytellingLeft li {
  font-size: 18px;
  font-weight: 500;
  color: var(--white);
  padding-bottom: 18px;
}
.storytellingLeft {
  margin-top: 32px;
}
.timeZoneArrow {
  position: absolute;
  right: 21px;
  top: 27px;
}

.scheduleDemoSection .react-calendar {
  border-radius: 20px;
  padding: 24px;
  width: 60%;
}

.scheduleDemoSection
  .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-weight: 600 !important;
  color: var(--black);
  text-transform: uppercase;
  font-size: 20px;
}

abbr[title] {
  color: rgba(160, 160, 160, 0.6);
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.scheduleDemoSection .react-calendar__tile--active {
  color: white !important;
  border-radius: 32px;
  background: rgba(255, 92, 93, 1) !important;
  font-size: 18px;
  font-weight: 500;
}

.scheduleDemoSection .react-calendar__tile:hover {
  color: white;
  border-radius: 32px;
  background: rgba(255, 92, 93, 1) !important;
  font-size: 18px;
  font-weight: 500;
}

.scheduleDemoSection .react-calendar__tile--now {
  color: white !important;
  border-radius: 32px;
  background: rgba(255, 92, 93, 48%);
  font-size: 18px;
  font-weight: 500;
}

.scheduleDemoSection .react-calendar__tile {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 1);
  padding: 12px;
}

.calanderOuter {
  display: flex;
}

.timeZoneSelect li {
  list-style: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
  border-radius: 30px;
  padding: 11px 24px;
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
  display: inline-block;
  margin-bottom: 10px;
  width: 43%;
  text-align: center;
  cursor: pointer;
}

.timeZoneSelect li.active,
.timeZoneSelect li:hover {
  border: 1px solid rgba(255, 92, 93, 1);
  background: rgba(255, 92, 93, 1);
}
.rc-time-picker-panel-combobox {
  display: flex;
}
.timeZoneSelect li:nth-child(odd) {
  margin-right: 25px;
}

.timeZoneSelect {
  margin-bottom: 0;
  width: 45%;
  height: 100%;
  max-height: 388px;
  overflow-y: auto;
}

.timeZoneSelect::-webkit-scrollbar {
  display: block;
  width: 4px;
}

/* .timeZoneSelect::-webkit-scrollbar-thumb {
  background-color: rgba(255, 92, 93, 1);
} */

.scheduleDemoSection .react-calendar__month-view__days__day--neighboringMonth,
.scheduleDemoSection
  .react-calendar__decade-view__years__year--neighboringDecade,
.scheduleDemoSection
  .react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575 !important;
}

.timeSelector .css-t3ipsp-control:hover,
.timeSelector .css-13cymwt:hover {
  border: 1px solid #ffffff4d !important;
  border-color: #ffffff4d !important;
  box-shadow: none !important;
  outline: none;
}

.timeSelector .css-13cymwt-control {
  border: 1px solid #ffffff4d !important;
  background: #ffffff1f !important;
  border-radius: 30px !important;
  padding: 12px 20px !important;
  color: #ffffff !important;
}

.timeSelector .css-t3ipsp-control {
  border: 1px solid #ffffff4d !important;
  background: #ffffff1f !important;
  border-radius: 30px !important;
  padding: 12px 24px !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.timeSelector .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.timeSelector .css-1wy0on6 {
  display: none;
}

.css-1dimb5e-singleValue {
  color: #ffffff !important;
  font-family: "Poppins";
  font-size: 16px !important;
  font-weight: 400 !important;
}

/* End Schedule a Demo Page Css */
.ticketHead_ .signUpBtn {
  display: flex;
  max-width: 186px;
  width: 100%;
}

.recentSubHead a {
  text-decoration: none !important;
}

.contactBox {
  border: 1px solid #ffffff4d;
  background: #ffffff1f;
  padding: 40px;
  width: 100%;
  max-width: 620px;
  border-radius: 30px;
  padding: 40px;
}

.contactUsSec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactPage .footer {
  margin-top: 90px;
}

#scheduleModal .modal-header {
  display: none;
}

.modalPara {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}

.errorImg {
  background-color: #ffffff;
  border-radius: 50%;
}

.disabledSlots {
  background: rgba(255, 255, 255, 0.2) !important;
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
  cursor: not-allowed !important;
}

.notificationBtn {
  display: flex;
  align-items: center;
  gap: 16px;
}

.roundCheck input[type="checkbox"] {
  position: relative;
  width: 36px;
  height: 20px;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 12px;
  background: #ff1616;
}

.roundCheck input[type="checkbox"]::before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: 3px;
  transition: 0.5s;
}

.roundCheck input[type="checkbox"]:checked::before {
  transform: translate(100%, -50%);
  background: #fff;
  border: none;
  border: 2px solid #fff;
}

.roundCheck input[type="checkbox"]:checked {
  background: #1bae21;
  border: 2px solid #1bae21;
}

.roundCheck .form-check-input:focus {
  border: none;
  box-shadow: none;
}

.notifyList {
  border: 1px solid #ffffff4d;
  background: #ffffff1f;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 16px;
}

.labelText {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #bdb9b9;
}

.enableBox {
  border: 1px solid #ffffff4d;
  background: #ffffff1f;
  padding: 12px 16px;
  border-radius: 12px;
}

.notifyMsgArea {
  display: flex;
  align-items: center;
  gap: 24px;
}

.notifyUser {
  width: 67px;
  height: 67px;
  position: relative;
  margin-bottom: 0 !important;
  min-width: 67px;
}

.notifyUserImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.bellNotify {
  background: #ff5c5d;
  border: 2px solid #ffffff;
  width: 30px;
  height: 29px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  bottom: 0px;
}

.notifyTime {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  color: #bdb9b9;
}

.unreadBtn {
  background: #ff5c5d;
  width: 14px;
  min-width: 14px;
  height: 14px;
  border-radius: 14px;
  margin-right: 24px;
  display: none;
}

.notifyList.active .unreadBtn {
  display: block;
}

.notifyList.active {
  border: 2px solid #ff5c5d80 !important;
  cursor: pointer;
}

.countValue {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #ff5c5d;
  border-radius: 50%;
  right: 1px;
  top: -5px;
}

.planTable .table {
  background: #131a1c;
  color: #fff;
  box-shadow: 0px 4px 12px 0px #0000000f;
  border-radius: 20px;
}

.planTable td,
.planTable th {
  border-bottom: 1px solid #2f3537;
  border-right: 1px solid #2f3537;
  padding: 14px 24px !important;
  vertical-align: middle;
}

.planTable tr td:last-child,
.planTable tr th:last-child {
  border-right: none;
}

.planTable tr:last-child td {
  border-bottom: none;
}

.planHead {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  padding: 12px 24px !important;
}

.planMain {
  background: #2f3537 !important;
  padding: 12px 24px;
}

.planHeading {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
}

.subscriptMain .footer {
  margin-top: 0;
}

.articleFlex {
  display: flex;
  align-items: center;
  gap: 16px;
}

.publicationSelect {
  background: #ffffff1f;
  border: 1px solid #ffffff4d;
  min-height: 56px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
}
.publicationSelect.searchBox {
  padding: 12px 16px;
}
.publicationSelect::-webkit-scrollbar {
  display: none;
}

.selectPublish {
  border: 1px solid #ffffff;
  padding: 6px 16px;
  gap: 6px;
  display: flex;
  align-items: center;
  border-radius: 100px;
}

.publishArrow {
  position: absolute;
  top: 24px;
  right: 14px;
  width: 12px;
  height: 10px;
}

.DescTicket {
  word-break: break-all;
}

.readBtn {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.articleimageinfo_ {
  width: 110px;
  height: 130px;
  border-radius: 20px;
  object-fit: contain;
}

.publishTime {
  padding: 16px !important;
}

.publishTime::-webkit-calendar-picker-indicator {
  background-image: url("../images/clockImg.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 20px 22px;
  cursor: pointer;
}

.td.noFoundHead {
  border: none !important;
}

.transactTable {
  margin-top: 40px;
  max-height: calc(72vh - 180px);
  overflow-y: scroll;
}

.transactTable .recentHead {
  min-width: 185px;
  width: 185px;
}

/* .transactTable .recentHead:nth-child(2) {
  min-width: 225px;
}

.transactTable .recentHead:nth-child(3) {
  min-width: 170px;
}

.transactTable .recentHead:nth-child(4) {
  min-width: 310px;
}

.transactTable .recentHead:nth-child(5) {
  min-width: 310px;
}

.transactTable .recentHead:last-child {
  min-width: 195px;
} */

.captchaBox {
  margin: 16px 12px;
}

.captchaBox div {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

select option:nth-child(odd) {
  background-color: rgb(73, 73, 73) !important;
  color: #fff;
}

select option:nth-child(even) {
  background-color: rgb(73, 73, 73) !important;
  color: #fff;
}

.table_Content_Summary {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}

.table_Content_Summary li {
  margin-top: 25px;
}

.table_Content_Summary strong {
  font-size: 32px;
  font-weight: 600;
}

.table_Content_Summary p strong {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
}

.table_Content_Summary p {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
}

.table_Content_Summary ::marker {
  font-size: 28px;
}
.privacy_Content_Summary {
  padding-bottom: 70px;
}

.schedulecontent_ .react-datepicker__header {
  text-align: center;
  background-color: #ff5c5d;
  border: 1px solid #ffffff;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
  color: #fff;
}

.schedulecontent_ .react-datepicker__day-name {
  color: #fff;
  display: inline-block;
  width: 2.5rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-weight: 700;
  font-size: 16px;
}

.schedulecontent_ .react-datepicker__current-month {
  margin-top: 0;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 6px;
}

.schedulecontent_ .react-datepicker__day {
  color: #000;
  display: inline-block;
  width: 2.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-size: 15px;
}

.schedulecontent_ .react-datepicker__day--selected {
  border-radius: 0.3rem;
  background-color: #ff5c5d;
  color: #fff;
}

.schedulecontent_ .react-datepicker__navigation-icon::before {
  border-color: #fff;
}

.CartItemBox {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  background-image: url("../images/cartPublication.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 30px;
  color: #ffffff;
  /* margin: 30px 0 10px 0; */
}

.cardInput_ {
  border: 1px solid #ffffff4d;
  padding: 16px;
  border-radius: 30px;
  background: #ffffff1f !important;
  color: #ffffff !important;
  background-color: transparent !important;
}
.ElementsApp.is-autofilled .InputElement:not(.is-empty),
.ElementsApp.is-autofilled .InputElement:-webkit-autofill {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
}

input.InputElement.is-complete.Input {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
}
/*sidebar responsive*/
.nav-link.dashBar_.mobileBar {
  display: none;
}
/* .ticket_Detail_Upload .deleteUpload {
  top: 5px;
  right: 25px;
} */
