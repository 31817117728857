* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* comman CSS  */
body {
  background-color: #000;
}

.subheading {
  font-family: "Poppins";
  font-size: 52px;
  font-weight: 600;
  line-height: 67px;
  color: #fff;
}

.fontSize28 {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  color: #fff;
}

.medium_h {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  line-height: 40px;
}

.second_big {
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  line-height: 45px;
}

.fontSize32 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 52px;
  color: #fff;
}

.fontSize20 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
}

.fontSize18 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
}

.sub_p_text {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 26px;
}

.grey_h {
  color: #a9a9a9 !important;
  text-decoration: none;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
}

.small-p {
  font-size: 15px;
  color: #fff;
}

/* button */
.nav_btn {
  padding-right: 0px !important;
}

.primary_btn {
  font-size: 16px;
  font-family: "poppins";
  padding: 16px 32px;
  border-radius: 30px;
  background: rgba(255, 188, 73, 1);
  border: 2px solid transparent;
  color: #fff;
  font-weight: 600;
  cursor: pointer !important;
}

.read_btn {
  background-color: transparent;
  color: #fff;
  border: 1px solid #ffffff;
  border-radius: 30px;
  padding: 12px 24px;
}

/* Navbar CSS */
.navbarMain ul li a {
  position: relative;
}

.navbarMain .navbarLink:hover::before,
.navbarMain .navbarLink.active::before {
  display: block;
}

.navbarMain .navbarLink:hover,
.navbarMain .navbarLink.active {
  color: rgba(255, 92, 93, 1);
}

.navbarMain .navbarLink::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 1px;
  bottom: 0px;
  left: 0;
  background-color: rgba(255, 92, 93, 1);
  right: 0;
  margin: 0 auto;
  display: none;
}

.logo {
  text-align: left;
}

.navigation {
  display: flex;
  justify-content: end;
}

.nav_links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.navigation .primary_btn {
  margin-right: 20px;
}

.navbar {
  justify-content: end;
}

.header .navbar ul {
  list-style: none;
  font-size: 16px;
  font-weight: 600;
}

.header .navbar ul li a {
  text-decoration: none;
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

/* .navbar ul li a:hover {
  color: rgba(255, 92, 93, 1);
  text-decoration: underline;
  text-underline-offset: 5px;
} */
.navbar.li {
  display: block;
}

/*banner CSS  */
.banner {
  position: relative;
  padding: 10px 0 120px 0px;
}

.getseen_txt {
  text-align: left;
  /* margin-left: 10%; */
  padding-right: 50px;
}

.search_bar {
  width: 100%;
  max-width: 662px;
  position: relative;
  display: inline-block;
  margin-top: 32px;
}

.search_icon {
  position: absolute;
  left: 20px;
  top: 17px;
}

.search_bar .primary_btn {
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 2px solid transparent;
  color: #131212;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 13px 32px;
}

.search_bar input {
  width: 100%;
  padding: 14px 56px;
  border-radius: 30px;
  border: 1px;
  outline: none;
  background: #ffffff1f;
  border: 1px solid #ffffff4d;
}

.search_bar input::placeholder {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.group_btn .primary_btn {
  border: 2px solid transparent;
  word-break: break-all;
}

.secondry_btn {
  padding: 16px 32px;
  border-radius: 30px;
  border: 2px solid transparent;
  background: rgba(255, 92, 93, 1);
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.banner::after {
  background-image: url("../../public/images/maskgrp1.png");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  position: absolute;
  max-width: 524px;
  width: 100%;
  top: -100px;
  left: 0;
  z-index: -1;
  height: 100vh;
}

/* 
.banner::after {
  background-image: url("../../public/images/maskgrp1.png");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  position: absolute;
  max-width: 400px;
  width: 100%;
  top: 30px;
  left: 0;
  z-index: -1;
  height: 100vh;
} */

/* .bannervideo {
  text-align: end;
} */
.bannervideo {
  text-align: end;
  position: absolute;
  right: 0;
  top: -14px;
  /* width: 100%; */
}

.video_img {
  max-width: 646px;
  width: 100%;
}

.home_Page_Vedio {
  display: none;
  max-width: 646px;
  width: 100%;
  padding-right: 10px;
}

.vedioIcon_Parent {
  position: absolute;
  top: 50%;
  right: 178px;
  transform: translate(-50%, -50%);
  max-width: 85px;
  width: 100%;
}

/* auto slider CSS */
.slider_img {
  max-width: 80%;
  width: 100%;
  margin: 25px 40px;
}

.slick-prev:before {
  display: none;
}

.slick-next:before {
  display: none;
}

.slick-arrow {
  display: none;
}

/* PR_sec css */
section.PR_sec {
  padding: 90px 0;
  background: linear-gradient(
    359.39deg,
    #131a1c -1.26%,
    rgba(19, 26, 28, 0.7) 34.89%,
    #030305 79.98%
  );
  background-image: url("../../public/images/prbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  border-radius: 110px;
}

.PR_sec .dashboard_img {
  max-width: 650px;
  width: 100%;
}

.PR_sec .PR_details {
  padding: 30px;
  margin-right: 27%;
}

/* End PR_sec section */

/* Start Strategic note section CSS*/

.about_main {
  margin: 120px 0;
  padding: 40px;
  border-radius: 30px;
  background: #131a1c;
  border-width: 0px 2px 0px 2px;
  border-left: 2px solid #ff5c5d;
  border-right: 2px solid #ffbc49;
}

.PR_sec .about_p {
  padding: 25px 65px 0;
}

.turn_txt.text-start {
  margin-left: 17%;
  /* padding: 38px; */
}

.turnaround_Txt {
  max-width: 530px;
  width: 100%;
}

.turn_img.img-fluid {
  max-width: 847px;
  width: 100%;
}

/* Start growth_sc CSS  */
.growth_sc {
  padding: 90px 0px 90px;
}

.growth_box {
  background-image: url("../../public/images/growthBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #fff;
  border-radius: 16px;
  display: flex;
  padding: 34px 0;
  margin: 30px;
  text-align: left;
}

.time_Saved {
  background-image: url("../../public/images/growthBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #fff;
  border-radius: 16px;
  display: flex;
  padding: 20px 0;
  margin: 30px;
  text-align: left;
}

.aboutgrowth_child {
  font-weight: 400;
  text-align: center;
  margin-bottom: 100px;
}

.aboutgrowth {
  padding-bottom: 0px;
}

/* Start Innovative sec CSS  */
.Innovative {
  background-image: url("../../public/images/InnovativeBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 120px 0;
  border-radius: 110px;
}

.InnovativeImg.img-fluid {
  max-width: 731px;
  width: 100%;
}

.Innovative_txt {
  text-align: left;
  padding: 57px;
  padding-bottom: 0 !important;
}

.Customization_box {
  padding: 80px 21px 0;
}

.small_P {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #fff;
}

.slidebar {
  margin: 20px 0 60px 0;
}

/* Transform-Section */

.transform_business {
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 120px 0;
}

.transform-laptop {
  max-width: 575px;
  width: 100%;
}

.services {
  margin-top: 30px;
}

.services ul {
  list-style: none;
  padding: 0;
}

.services_List {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  color: #fff;
}

.Offers_box {
  /* background-image: url("./Images/rectanglebitcoin.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding-bottom: 50px;
  margin: 20px;
}

/* Section testimonial CSS */
.testimonial {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  padding: 120px 0;
}

.del_day {
  color: #bebebe;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.marqueImg {
  margin-right: 50px;
}

.marquee_Links {
  text-decoration: none;
}

/* Section testimonial slider CSS */
.change {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 92, 93, 0.29) 93.5%
  );
  box-shadow: 0px 4px 12px 0px #0000000f;
}

.slider_box {
  background-color: rgba(17, 23, 25, 1);
  border-radius: 30px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  margin: 70px 10px 0;
  padding: 55px 10px;
}

.slider_box.change {
  background-color: transparent;
}

.img_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.slider_Heading {
  height: 90px;
  padding: 0 20px;
}

.slider_Heading.medium_h {
  line-height: 34px !important;
}

.circle_img {
  max-width: 100px;
  width: 100%;
  height: 100px;
  position: absolute;
  top: -50px;
}

.pin_location {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pin {
  margin-right: 10px;
}

.slider_txt {
  padding: 20px 40px 0;
  text-align: center;
  position: relative;
}

button.slick-arrow.slick-prev {
  background-image: url("../../public/images/backBtn.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 22px;
  position: absolute;
  left: 0;
  z-index: 1;
  color: #fff;
  border-radius: 30px;
  top: 58%;
}

button.slick-arrow.slick-next {
  background-image: url("../../public/images/nextBtn.svg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 22px;
  position: absolute;
  right: 0;
  z-index: 1;
  color: #fff;
  border-radius: 30px;
  top: 58%;
}

/* Section Publish Css */
.publish {
  padding: 120px 0;
  /* background-image: url("./Images/publishBg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #000;
}

img.public_icon {
  max-width: 60px;
  width: 60px;
  height: 60px;
  margin-left: -32px;
  margin-right: 20px;
}

.colored_txt {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #ff5c5d;
}

/* three step solution section CSS */
.three_step {
  padding: 100px 0 70px;
}

.step_one {
  max-width: 416px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid;
  margin: auto;
  padding: 40px 58px;
  background-color: #131a1c;
  border-color: rgba(30, 234, 255, 1) rgba(110, 56, 255, 1) rgba(246, 0, 255, 1);
}

.step_box {
  position: relative;
  text-align: center;
}

.step_img {
  position: absolute;
  left: 8px;
  right: 0;
  top: 367px;
}

.steptwo_img {
  margin-bottom: 60px;
  text-align: center;
}

.step_two {
  position: relative;
}

.arrowup_side {
  position: absolute;
  top: 157px;
  left: 0;
  right: 0;
  transform: rotate(180deg);
  text-align: center;
}

.right_arrow {
  position: absolute;
  top: 71px;
  left: -110px;
}

.left_arrow {
  position: absolute;
  top: 71px;
  right: -101px;
}

/* Section faq CSS*/
.accordion-button:focus {
  box-shadow: none;
  border-radius: 30px;
}

/* new rule accordian */
.accordion-item.mt-3 {
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.12);
  color: #fff;
  border: none !important;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 20px;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 20px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - -20px);
  border-bottom-left-radius: calc(0.25rem - -20px);
}

/* .accordion-item{
 border-radius: 30px;
} */
.faq {
  padding-bottom: 100px;
}

.frequently {
  text-align: center;
  padding: 0px 0px 50px;
}

.arrow_parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 12px;
  height: 8px;
  margin-left: auto;
  content: "";
  background-image: url("../../public/images/arrowDown.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: transform 0.2s ease-in-out;
  /* background-color: white; */
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../public/images/arrowDown.png");
  transform: rotate(-180deg);
}

.accordion-button {
  background-color: rgba(255, 255, 255, 0.12);
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.12);
}

/*Section Connect With Us CSS */
.connect_US {
  position: relative;
  background-image: url("../../public/images/connectUsBg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 60px;
}

.sign_up {
  max-width: 417px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  padding: 40px;
  position: absolute;
  right: 120px;
  top: -70px;
  background: #131a1c;
}

.connect_parent {
  margin: 100px 0;
}

.sign_up::after {
  position: absolute;
  background-color: #000 !important;
  padding: 38px 40px 32px 40px;
  border: 1px;
  z-index: 1;
}

.newsletter {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #fff;
}

.big_story {
  margin-top: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.email {
  margin-top: 24px;
  max-width: 340px;
  width: 100%;
  height: 48px;
  border-radius: 30px;
  border: 1px;
  padding: 0px 24px 0px 24px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.12);
}

::placeholder {
  color: #fff;
}

.robot_check {
  margin-right: 16px;
}

.sign_Submit {
  margin-top: 32px;
}

.secondry_btn {
  padding: 16px 32px;
  border-radius: 30px;
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border: 2px solid transparent;
}

.agreement {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
}

/* Footer Css */
.homeConnetUS_Margin {
  margin-bottom: 180px;
}

.footer {
  padding: 60px 30px 0px;
}

.ownbuzz_detail .grey_h {
  line-height: 24px;
}

/* background-color: #000; */
.footer_nav .footer_List {
  list-style: none;
  padding: 0;
  text-decoration: none;
  line-height: 32px;
  margin-top: 20px;
}

ul.footer_List {
  padding: 0;
  line-height: 32px;
  list-style: none;
  margin-top: 20px;
}

.submit {
  width: 100%;
  background-color: #ff5c5d;
  border: none;
  border-radius: 30px;
  padding: 16px;
  font-weight: 600;
}

.otherPageLink {
  color: #1eeaff !important;
  text-decoration: none;
  font-weight: 600;
}

.footer {
  background: #131a1c;
}

.footer_List :nth-child(1 of .footerIcon) {
  width: 6.56px;
  height: 12.5px;
}

.footerIcon {
  width: 15px !important;
  height: 15px !important;
  margin-right: 10px;
}

/* Start Subling Section */
.subscribingSection {
  padding: 100px 0 0 0;
}

/* Start Header Css */
.logo {
  max-width: 240px;
  width: 100%;
}

.header {
  min-height: 80px;
  width: 100%;
  position: relative;
  padding: 40px 0 80px 0px;
  background-size: cover;
}

.navbar_box {
  padding: 0;
  flex-wrap: nowrap;
}

.navbar-brand {
  height: auto;
  margin: 0 0;
  padding: 10px 15px 10px 0;
}

.navbar-nav {
  margin: 0 0 0;
}

.navbar-nav li {
  padding: 0 15px;
}

.navbar-light .navbar-nav .nav-link {
  color: #333;
  font-size: 16px;
  line-height: 20px;
  padding: 0;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 600;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: end;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
  font-size: 16px;
  line-height: 20px;
  padding: 0;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-family: "Sifonn";
}

.navbar-nav {
  margin: 0 0 0;
  align-items: center;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #dce9fb !important;
  font-weight: 500 !important;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #dce9fb !important;
}

.navbar-toggler-icon i::before {
  content: "" !important;
}

li.header_btn {
  display: flex;
  align-items: center;
}

.button_group {
  margin: 10px 20px;
}

.header button.navbar-toggler {
  background-color: #fff;
}

.slider_box.change .small-p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  height: 95px;
}

.footerLight {
  margin: 15px 0;
}

.faq .medium_h {
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}

.popular_offer {
  margin-bottom: 50px;
}

/* End Header Css */

/* responsive CSS :start */
@media screen and (min-width: 1600px) {
  .sign_up {
    right: 280px;
  }

  .PR_sec .about_p {
    padding: 25px 221px 0;
  }
}

@media screen and (max-width: 1365px) {
  .sign_up {
    right: 90px;
  }
}

@media screen and (max-width: 1400px) {
  .second_big {
    font-size: 37px;
  }

  .faq .medium_h {
    font-size: 37px;
  }

  .secondry_btn {
    padding: 12px 25px;
  }

  .primary_btn {
    padding: 12px 25px;
  }

  .navbar-nav li {
    padding: 0 8px;
  }

  /* .getseen_txt {
    margin-left: 58px;
    padding: 50px;
  } */

  .PR_sec .PR_details {
    margin: 0;
  }

  /* .turn_txt.text-start {
    margin: 0;
  } */
}

@media screen and (max-width: 1200px) {
  .vedioIcon_Parent {
    right: 100px;
  }

  .video_img {
    max-width: 465px;
  }

  .getseen_txt .subheading.mt-4 {
    font-size: 40px;
  }

  .getseen_txt .medium_h.mt-4 {
    margin-top: 5px !important;
    font-size: 20px;
  }

  .getseen_txt .sub_p_text.mt-4 {
    margin-top: 5px !important;
  }

  .getseen_txt .group_btn.mt-5 {
    margin-top: 10px !important;
  }

  .header .navbar ul li a {
    font-size: 14px;
  }

  .logo {
    max-width: 175px;
    width: 100%;
  }

  .secondry_btn {
    padding: 10px 20px;
  }

  .primary_btn {
    padding: 10px 20px;
  }

  .second_big br {
    display: none;
  }

  .Innovative {
    padding: 50px 0;
  }

  .Innovative_txt {
    padding: 30px;
  }

  .Customization_box {
    padding: 30px 20px 0;
  }

  /* .footer {
    margin-top: 110px;
  } */
  .homeConnetUS_Margin {
    margin-bottom: 110px;
  }

  .group_btn .secondry_btn {
    margin: 20px 0;
  }

  .group_btn.mt-5 {
    margin-top: 20px !important;
  }

  .navbar ul {
    font-size: 13px;
  }

  .timeZoneSelect li {
    font-size: 12px;
  }

  .scheduleDemoSection .heading48 {
    font-size: 46px;
    line-height: 55px;
  }
}

@media screen and (max-width: 992px) {
  .banner_col_one {
    order: 2;
  }

  .bannervideo {
    position: relative;
    margin-top: 20px;
    text-align: center;
  }

  .video_img {
    display: none;
  }

  .vedioIcon {
    display: none;
  }

  .home_Page_Vedio {
    display: block;
  }

  .vedioIcon_Parent {
    left: 134px;
    right: 0;
    margin: auto;
  }

  .banner::after {
    /* max-width: 384px; */
    width: 100%;
    top: 100px;
  }

  .Innovative_txt {
    padding: 12px;
  }

  .navbar {
    background: #0000006b;
    border-radius: 15px;
    margin: 0 auto !important;
  }

  .navbar ul {
    margin: 0 auto !important;
  }

  .navbar-nav li {
    width: 100%;
    text-align: center;
    margin: 10px;
    justify-content: center;
  }

  .navbar {
    margin-top: 24px;
  }

  .group_btn {
    margin-top: 10px !important;
  }

  .subheading {
    font-size: 40px;
    line-height: 50px;
  }

  .medium_h {
    font-size: 20px;
  }

  .second_big {
    font-size: 30px;
  }

  .faq .medium_h {
    font-size: 30px;
  }

  .search_bar {
    margin-top: 20px;
  }

  section.PR_sec {
    padding: 50px 0;
  }

  .about_main {
    margin: 50px 0;
  }

  .PR_sec .about_p {
    padding: 0;
  }

  .growth_sc {
    padding: 50px 0;
  }

  .subscribingSection {
    padding: 60px 0;
  }

  .subscribingSection_txt {
    padding: 0px 30px;
  }

  .sign_up {
    position: static;
    /* margin: 30px; */
  }

  .testimonial {
    padding: 50px 0;
  }

  .faq {
    padding-bottom: 50px;
  }

  .scheduleDemoSection .react-calendar {
    width: 100%;
  }

  .scheduleDemoSection .heading48 {
    font-size: 46px;
    line-height: 55px;
  }

  .calanderOuter {
    flex-direction: column;
  }

  .timeZoneSelect li {
    padding: 11px 12px;
    font-size: 12px;
  }

  .timeZoneSelect {
    width: 100%;
    margin-top: 20px;
  }

  .aboutRightimage_ {
    position: relative;
    margin-top: 30px;
  }

  .aboutLeftcontent_ .sub_p_text {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .connect_parent {
    margin: 50px 30px;
  }

  .banner {
    padding: 0 0 50px;
  }

  .getseen_txt {
    padding: 20px;
  }

  .connect_US {
    margin-top: 0;
  }

  .scheduleDemoSection .headingGray32 {
    font-size: 28px;
  }

  .scheduleDemoSection .heading48 {
    font-size: 40px;
    line-height: 55px;
  }

  .scheduleDemoSection .storytellingLeft li {
    font-size: 16px;
  }

  .scheduleDemoSection .react-calendar {
    width: 100%;
  }

  .calanderOuter {
    flex-direction: column;
  }

  .timeZoneSelect li {
    padding: 11px 12px;
    font-size: 12px;
  }

  .timeZoneSelect {
    width: 100%;
    margin-top: 20px;
  }

  /* privacy and policy */
  .table_Content_Summary {
    margin-left: 0px;
    margin-top: 20px;
  }

  /* .table_Content_Summary ul li {
    list-style: decimal;
    padding: 0px 5px 15px;
  } */

  .privacy_table_ul li {
    padding: 0px 5px 8px;
    list-style: decimal;
  }

  .privacy_Txt_Parent {
    margin-bottom: 40px;
  }

  .privacy_Lastdate {
    margin-top: 25px;
  }

  .turn_txt.text-start {
    margin-left: 0;
    padding: 38px;
  }

  .chooseOption_ .authSubText {
    margin-top: 20px;
  }

  .footer_nav {
    margin-top: 20px;
  }

  .table_Content_Summary p strong {
    font-size: 22px;
    line-height: 37px;
  }

  .table_Content_Summary p {
    font-size: 16px;
  }

  .table_Content_Summary ::marker {
    font-size: 20px;
  }

  .slider_Heading {
    height: 50px;
  }
}

@media screen and (max-width: 576px) {
  .about_main {
    margin: 20px 0;
  }

  .home_Faq {
    padding-bottom: 0;
  }

  .Customization_box.mt-5 {
    margin-top: 0 !important;
  }

  .slider_Heading.medium_h {
    line-height: 30px !important;
  }

  .medium_h {
    font-size: 20px;
    margin-top: 12px !important;
  }

  .fontSize32 {
    font-size: 26px;
    line-height: 36px;
  }

  .fontSize18 {
    font-size: 16px;
  }

  .subheading {
    font-size: 38px;
    line-height: 30px;
  }
  .subscriptionBanner_ .subheading {
    line-height: 50px;
  }
  .faq .medium_h {
    font-size: 25px;
  }

  .second_big {
    font-size: 25px;
    line-height: normal;
  }

  .header button.navbar-toggler {
    padding: 5px 7px;
  }

  .getseen_txt {
    margin-left: 0;
  }

  .header .navbar-toggler-icon {
    width: 18px;
    height: 22px;
  }

  .search_bar .primary_btn {
    font-size: 14px;
    padding: 14px 18px;
  }

  .scheduleDemoSection .headingGray32 {
    font-size: 24px;
  }

  .scheduleDemoSection .heading48 {
    font-size: 35px;
    line-height: 48px;
  }

  .scheduleDemoSection .storytellingLeft li {
    font-size: 16px;
  }

  .scheduleDemoSection .react-calendar {
    width: 100%;
  }

  .calanderOuter {
    flex-direction: column;
  }

  .timeZoneSelect li {
    padding: 11px 12px;
    font-size: 12px;
  }

  .timeZoneSelect {
    width: 100%;
    margin-top: 20px;
    padding: 0;
  }

  /* privacy and policy */
  .table_Content_Summary {
    margin-left: 0px;
    margin-top: 20px;
  }

  .table_Content_Summary ul li {
    list-style: decimal;
    padding: 0px 5px 15px;
  }

  .privacy_table_ul li {
    padding: 0px 5px 8px;
    list-style: decimal;
  }

  .privacy_Txt_Parent {
    margin-bottom: 40px;
  }

  .privacy_Lastdate {
    margin-top: 25px;
  }

  .turn_txt.text-start {
    /* margin-left: 17%; */
    padding: 20px;
  }

  .table_Content_Summary p strong {
    font-size: 18px;
    line-height: 28px;
  }

  .table_Content_Summary p {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .header {
    padding: 40px 0 40px 0px;
  }

  .aboutLeftcontent_.mt-5 {
    margin-top: 20px !important;
  }

  .about_Us_Banner {
    padding-bottom: 50px;
  }

  .why_Choose {
    padding: 60px 0 60px;
  }

  .authSubText {
    font-size: 20px;
  }

  .getseen_txt {
    padding: 12px;
  }

  .aboutgrowth_child {
    margin-bottom: 25px;
  }

  .fontSize32 {
    font-size: 24px;
    line-height: 32px;
  }

  .fontSize18 {
    font-size: 16px;
  }

  .subheading {
    font-size: 30px;
    line-height: 42px;
  }
  .subscriptionBanner_ .subheading {
    line-height: 42px;
  }
  .medium_h {
    line-height: normal;
  }

  .secondry_btn {
    font-size: 14px;
    padding: 8px 12px;
    margin-right: 16px;
  }

  .primary_btn {
    padding: 8px 12px;
    font-size: 14px;
  }

  .vedioIcon_Parent {
    left: 32%;
  }

  .vedioIcon {
    height: 50px;
    max-width: 50px;
    width: 100%;
  }

  .scheduleDemoSection .headingGray32 {
    font-size: 24px;
  }

  .scheduleDemoSection .heading48 {
    font-size: 28px;
    line-height: 42px;
  }

  .scheduleDemoSection .storytellingLeft li {
    font-size: 16px;
  }

  .scheduleDemoSection .react-calendar {
    width: 100%;
  }

  .getIn_Touch_Parent .subheading {
    line-height: 40px !important;
    font-size: 22px;
  }

  .getIntouch_Btn {
    margin-top: 20px;
  }

  .getIn_Touch_Parent {
    padding: 60px 0px !important;
  }

  .mainRules_:before {
    width: 348px;
    height: 256px;
  }

  .mainRules_ {
    padding: 30px 20px;
  }

  .homeConnetUS_Margin {
    margin-bottom: 30px;
  }

  .footer {
    margin-top: 0px;
  }

  .footer_nav .footer_List {
    margin-top: 2px;
  }

  ul.footer_List {
    margin-top: 2px;
  }

  .aboutUs_Slider {
    margin: 0px !important;
  }

  .chooseOption_ .authSubText {
    margin-top: 20px;
  }

  .aboutRightimage_ {
    margin: 20px;
  }

  .aboutHeading_ .aboutPara_ {
    font-size: 23px;
    line-height: 38px;
  }

  .aboutHead_ {
    font-size: 60px;
    line-height: 95px;
  }

  /* privacy and policy */
  .table_Content_Summary {
    margin-left: 0px;
    margin-top: 20px;
  }

  .table_Content_Summary ul li {
    list-style: decimal;
    padding: 0px 5px 15px;
  }

  .privacy_table_ul li {
    padding: 0px 5px 8px;
    list-style: decimal;
  }

  .privacy_Txt_Parent {
    margin-bottom: 40px;
  }

  .privacy_Lastdate {
    margin-top: 25px;
  }
}

/* Chnages By Rohit */
/* About Us Page */
.experience_count {
  background-image: linear-gradient(#000000, #313131) !important;
}

.about_Sliderbar {
  background-image: linear-gradient(#000000, #313131) !important;
}

.aboutUs_Slider {
  margin: 100px 0px;
}

.about_JoinOwnbuzz {
  text-align: center;
  background-image: url("../../public/images/about_getintouch_Img.png");
}

.getIn_Touch_Parent {
  padding: 100px 0px;
}

.getIn_Touch_Parent .gettouch_Txt {
  line-height: 67px;
}

.getIntouch_Btn {
  margin-top: 48px;
}

/* End About US */

/* Start Home Page */
.ronand_Txt {
  margin-top: 20px;
}

.slider_Quotes_Up {
  position: absolute;
  top: -5px;
  opacity: 30%;
}

.slider_Quotes_Down {
  position: absolute;
  bottom: -15px;
  opacity: 30%;
  right: 23px;
}

/* Section FAQ Section*/
.faq {
  margin: 0px 0px 80px 0px;
}

button.accordion-button:focus-visible {
  outline: none !important;
  border: none;
}

button.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button {
  background: #1f1f1f !important;
  color: rgba(255, 255, 255, 1) !important;
  padding: 26px 26px !important;
  font-family: "Poppins" !important;
}

.accordion-item {
  background: #4c4c4c !important;
  color: rgba(255, 255, 255, 1) !important;
  text-align: left !important;
  border: none !important;
  border-radius: 20px !important;
  font-family: "Poppins" !important;
}

.accordion-button:not(.collapsed) {
  background: #1f1f1f !important;
  color: rgba(255, 255, 255, 1) !important;
  border: none !important;
  border-radius: 20px 20px 0px 0px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  box-shadow: none !important;
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.accordion-button {
  background: #1f1f1f !important;
  color: rgba(255, 255, 255, 1) !important;
}

.accordion-item:first-of-type {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.accordion-item:first-of-type > .accordion-header .accordion-button {
  color: rgba(255, 255, 255, 1) !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  font-size: 18px !important;
  font-weight: 18px !important;
  box-shadow: none !important;
}

.accordion-button::after {
  /* background-image: url("../images/accorditionArrow.png") !important; */
}

.accordion-button:not(.collapsed)::after {
  /* background-image: url("../images/accorditionArrow.png") !important; */
}

button.accordion-button.collapsed {
  border: none !important;
  border-radius: 20px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.accordion-item.mt-3 {
  border: none !important;
  border-radius: 20px !important;
}

.accordion-body {
  background: #1f1f1f !important;
  border-radius: 0px 0px 20px 20px !important;
  color: rgba(255, 255, 255, 1) !important;
  /* padding-right: 150px !important; */
  font-size: 16px !important;
  padding-left: 26px;
  font-family: "Poppins" !important;
  padding-top: 0 !important;
}

/* End FAQ Section*/

/* Capcha Box */
.capcha-box {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid #ffffff4d;
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
}

.form-group {
  display: block;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  border: 3px solid rgba(221, 221, 221, 1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 10px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.subscriptMain section.faq {
  margin-top: 75px;
}

/* Faq Media Query */
@media screen and (max-width: 576px) {
  .faq {
    margin: 30px 0px !important;
  }

  .scheduleDemoSection {
    padding-bottom: 30px;
  }

  /* ul.timeZoneSelect {
    text-align: center;
  } */

  .subscription_Continue_Btn {
    text-align: center;
  }

  .subcription_Faq {
    margin-top: 30px !important;
  }

  .frequently.textSize48 {
    font-size: 26px;
  }

  .yloreAccordion.mt-5 {
    margin: 10px 0px !important;
  }

  .accordion-item:first-of-type > .accordion-header .accordion-button {
    font-size: 14px !important;
  }

  button.accordion-button.collapsed {
    font-size: 14px !important;
  }

  .accordion-button:not(.collapsed) {
    font-size: 16px !important;
  }

  .accordion-body {
    padding-right: 10px !important;
    font-size: 12px !important;
  }

  .table_Content_Summary .sub_p_text {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 22px;
  }
}

@media screen and (max-width: 992px) and (min-width: 576px) {
  .faq {
    margin: 30px !important;
  }

  .subscription_Continue_Btn {
    text-align: center;
  }

  .frequently.textSize48 {
    font-size: 34px !important;
  }

  .yloreAccordion.mt-5 {
    margin: 20px 0px !important;
  }

  .accordion-item:first-of-type > .accordion-header .accordion-button {
    font-size: 16px !important;
  }

  .accordion-body {
    font-size: 14px !important;
    padding: 10px 20px !important;
  }

  button.accordion-button.collapsed {
    font-size: 16px !important;
  }

  .table_Content_Summary {
    margin-top: 30px;
    margin-left: 0 !important;
  }

  .chooseOption_ .authSubText {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1440px) and (min-width: 992px) {
  .frequently.textSize48 {
    font-size: 34px !important;
  }
}

/*Footer Faq Page */
.faqPage_frequently {
  padding: 0px;
}

.faqpage_parent {
  padding: 48px 60px;
  border-radius: 30px;
  background: #ffffff1f !important;
}

.footerpage_faq_Parent {
  background-image: linear-gradient(#000000, #313131);
}

.faq.faq_footer_Page {
  margin: 0 !important;
}

.footerpage_accordion {
  background: #4c4c4c !important;
}

.faqpage_accordionItem:first-of-type > .accordion-header .accordion-button {
  background: #4c4c4c !important;
}

.footerpage_accordion:not(.collapsed) {
  background: #4c4c4c !important;
}

.faqpage_accordionbody {
  background: #4c4c4c !important;
}

/* End Footer faq Page */

/* Sechedule a Demo */
.nav_section_bg {
  background-image: linear-gradient(#000000, #313131);
}

@media screen and (max-width: 1200px) and (min-width: 576px) {
  .clientLogoOuter {
    width: auto;
   margin-right: 20px;
}
}
@media screen and (max-width: 576px) {
  .clientLogoOuter {
    width: 310px;
   margin-right: 20px;
}
}
