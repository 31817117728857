/***Common Css***/
.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.textWhite {
  color: #ffffff;
}

.passionate_People {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.heading_22 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 500;
}

.modal-backdrop.show {
  background: #000000 !important;
  --bs-backdrop-opacity: 0.8 !important;
}

.modal-content {
  background: #242525;
  border: 1px solid rgba(42, 42, 42);
  border-radius: 30px;
}
#paymentSuccess .modal-header {
  position: relative;
  padding: 24px !important;
  justify-content: center !important;
  border-bottom: none !important;
}
#paymentSuccess .modal_cancel {
  position: absolute;
  top: 18px;
  right: 24px;
  cursor: pointer;
}
#paymentSuccess .modal-body {
  padding: 0 20px 18px 20px !important;
  overflow-y: auto;
}
.modal-header {
  position: relative;
  padding: 40px !important;
  justify-content: center !important;
  border-bottom: 1px solid rgba(47, 53, 55, 1) !important;
}

/**Start Auth page css**/
.authBg {
  /* background-image: url("../images/authbg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.authBg .card.card-outline.card-primary.cardTopOutline.cardBg {
  background-color: #ffffff14 !important;
  border: 1px solid #ffffff14 !important;
  padding-top: 25px;
}

.card-primary {
  max-width: 608px;
  width: 100%;
}

.adminlgn .login-card-body {
  background-color: #ffffff1f;
}

.authBg .login-card-body {
  background-color: transparent;
  border-radius: 30px;
}

.authBg .card-header.text-center.border-0 {
  padding-bottom: 40px;
}

.form_subheading {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.logintext {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  font-family: Poppins;
  line-height: 52px;
  text-align: center;
}

.card.card-outline.card-primary.cardTopOutline.cardBg {
  border-radius: 30px;
  border: none;
}

.navbar-white {
  background-color: #343a40;
  color: #1f2d3d;
}

.admin-name-image {
  display: flex;
  align-items: center;
  gap: 11px;
}

.admin-name {
  margin: 0;
  font-size: 20px;
  color: #fff;
}

.navbar-inner ul {
  display: flex !important;
}

.navbar-inner ul li a:hover {
  color: #000 !important;
  text-decoration: none !important;
  text-underline-offset: 5px;
}

.navbar-inner ul li a {
  color: #000 !important;
}

.loginBtnCommon {
  border: none;
  background: linear-gradient(
    101.17deg,
    #1eeaff 0%,
    #6e38ff 49.5%,
    #f600ff 100%
  );
  color: #fff;
  padding: 11px 20px;
  border-radius: 7px;
}

@media screen and (max-width: 768px) {
  .card-primary {
    max-width: 450px;
  }
}

@media screen and (max-width: 475px) {
  .adminlgn .card-body.login-card-body {
    padding: 0 30px;
  }

  .card-primary {
    max-width: 300px;
  }

  .authBg button.loginBtnCommon.btnYellow.mt-5.mw-100.loginbtn {
    margin: 30px 0 !important;
  }

  .adminpwd .login-card-body {
    padding: 0 20px 20px;
  }

  .adminotp .otpForm input.enterOtp {
    width: 40px !important;
    height: 40px !important;
  }

  .adminotp .card-body.login-card-body {
    padding: 0 30px 30px;
  }

  .adminotp .card-header.text-center.border-0 {
    padding: 30px;
  }

  .buttonBox.mt-5 {
    margin-top: 30px !important;
  }

  .adminpwd button.loginBtnCommon {
    margin-top: 16px;
  }
}

/* start  Article Categories CSS  */
.categories .categories_group {
  display: flex;
  align-items: center;
  padding: 24px;
}

.categories .delete {
  background-color: #f44336;
  width: 30px;
  height: 30px;
  border: none;
  padding-bottom: 3px;
  border-radius: 50%;
}

.categories .searchSection {
  margin-bottom: 32px;
}

.categories button.loginBtnCommon.btnYellow.add_btn {
  width: 159px;
  border-radius: 30px;
  padding: 16px 32px 16px 32px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.uploadImgeboxNewArticle {
  border: 1px dashed #bbbaba;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border-radius: 40px;
}



/* .uploadImgeboxNewArticle .uploadfileIcon {
  height: 170px;
  width: 400px;
  margin-left: 20px;
} */
.uploadImgeboxNewArticle .uploadfileIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.uploadArticle_UploadImage {
  width: 170px;
  height: 170px;
  min-width: 170px;
}

.articlesFeed {
  display: flex;
  justify-content: space-between;
}

/* reetu css start */
/*auth css start*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
  border-radius: 40px;
  margin: 0 20px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pointCursor {
  cursor: pointer;
}

figure {
  margin: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

/* a,
button {
  cursor: pointer;
} */

body {
  box-sizing: border-box;
}

.authbgSection {
  background-image: url("../images/authBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.authOuterSec {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.authLogoImg {
  margin: 40px 100px;
}

.authInnerSec {
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 40px;
  border-radius: 30px;
  width: 100%;
  max-width: 620px;
  margin: 24px;
}

.authheading {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.authPara {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}

.uplaodUser {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}

.uploadSec {
  width: 100%;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #ffffff4d;
  position: relative;
  max-width: 120px;
  min-width: 120px;
}

.userImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.camImgBx {
  background: #ff5c5d;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadlabel input {
  display: none;
}

.uploadlabel {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.nameControl {
  border: 1px solid #ffffff4d !important;
  background: #ffffff1f !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: left;
  border-radius: 30px;
  padding: 16px 24px;
  color: #ffffff !important;
}

.nameControl::placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: left;
  color: #ffffff !important;
}

.form-control:focus {
  color: #212529;
  background-color: #ffffff1f !important;
  border-color: #ffffff4d !important;
  outline: none !important;
  box-shadow: none !important;
}

.eyeIcon {
  position: absolute;
  top: 16px;
  right: 24px;
  cursor: pointer;
}

.phoneControl .react-tel-input .form-control {
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 100%;
  outline: none;
  width: 76%;
  border: 1px solid #ffffff4d !important;
  background: #ffffff1f !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: left;
  padding: 16px 24px !important;
  color: #ffffff !important;
  border-radius: 30px;
  left: 24%;
}

.phoneControl .react-tel-input .form-control::placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: left;
  color: #ffffff !important;
}

.phoneControl {
  width: 100%;
}

.phoneControl .react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 16px 20px !important;
  border: 1px solid #ffffff4d !important;
  background: #ffffff1f !important;
  border-radius: 30px;
  left: 0;
  width: 22%;
}

.phoneControl .react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  margin: 0 auto;
  padding: 0 !important;
  border-radius: 3px 0 0 3px;
}

.phoneControl .react-tel-input .selected-flag:hover,
.phoneControl .react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.phoneControl .react-tel-input .country-list {
  max-height: 116px;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
  border-radius: 3px 0 0 0;
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid #fff;
  left: 28px;
}

.react-tel-input .selected-flag .arrow {
  border-bottom: 4px solid #fff;
  left: 28px;
  border-top: none;
}

.form-group.checkbx {
  display: block;
  margin-bottom: 15px;
}

.form-group.checkbx input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group.checkbx label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}

.form-group.checkbx label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #ff5c5d;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
}

.form-group.checkbx input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
  width: 5px;
  height: 11px;
  border: solid #ff5c5d;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.linkText {
  color: #ff5c5d !important;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.signUpBtn {
  padding: 16px 32px;
  border-radius: 30px;
  background: #ff5c5d;
  border: none;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #ffffff !important;
}
.articleRejectedBtn {
  background: transparent;
  border: 2px solid #ff5c5d;
  padding: 7px 32px;
  border-radius: 30px;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
}

.linkSub {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #ff5c5d !important;
  text-decoration: none;
}

.authOtherSection {
  /* height: 100vh; */
  min-height: 100vh;
}

.authOtherSection .authOuterSec {
  margin-top: 30px;
}

.authOtpSec div {
  justify-content: center !important;
  gap: 24px;
}

.authOtpSec input {
  width: 72px !important;
  height: 72px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #ffffff1f;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #fff;
}

input:focus-visible {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff1f inset !important;
}

input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

.authSubText {
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.emailSuccessSec {
  height: 100vh;
}

.emailSuccessSec .authOuterSec {
  height: 70%;
}

.otpOuterBx {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.otpTimeSub button,
.otpTimeSub span {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #ff5c5d !important;
  text-decoration: none;
  background-color: transparent !important;
  font-family: "poppins" !important;
}

/*auth css end*/
/*layout css start*/
.content-wrapper {
  background: #3d3d3d;
  height: calc(100vh - 110px);
  overflow-y: scroll;
  margin-right: 23px;
  padding: 24px 40px;
}

.content-wrapper::-webkit-scrollbar {
  display: none;
}

.mainNavbar {
  padding: 23px 23px 23px 40px;
  border-bottom: 1px solid #373737;
}

.handImg {
  width: 32px;
  height: 32px;
}

.navRight {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: end;
}

.navLeft {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: start;
}

.profileText {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  text-transform: capitalize;
}

.userProfileImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: block;
  border: none;
  background: transparent;
}

.userImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.bellBtn {
  width: 48px;
  height: 48px;
  background-color: #ffffff;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.mainBox {
  height: 100vh;
  background: linear-gradient(253.83deg, #000000 -0.44%, #313131 100%);
}

.dashboardMain {
  width: calc(100vw - 285px);
  margin-left: 285px;
  height: 100vh;
  transition: all 0.6s;
}

.mainSidebar {
  border: 1px solid #373737;
  width: 285px;
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: 999;
  transition: 0.4s;
  background: hsl(0deg 0% 14.33%);
}

.mainSidebar.full .sideToggle {
  transform: rotate(180deg);
}

.mainSidebar.hide {
  width: 100px;
}

.mainSidebar.hide .dashboardMain {
  width: calc(100vw - 110px);
  margin-left: 100px;
  height: 100vh;
}

.brandLink {
  padding: 35px 35px 20px 20px;
  display: block;
  border-bottom: 1px solid #373737;
}

.sideHeading {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #808080;
  margin-left: 16px;
}

.sidebarOuter {
  padding: 0px 35px 0px 15px;
  height: calc(100vh - 130px);
  overflow-y: scroll;
}

.sidebarOuter::-webkit-scrollbar {
  display: none;
}

.sidebarInner {
  margin-top: 30px;
}

.sideLinkText {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #aeaeae;
  text-transform: capitalize;
}

.sidebarLink {
  text-decoration: none !important;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 20px 10px 12px;
  border-radius: 30px;
}

.sidebarLink.active,
.sidebarLink:hover {
  background: rgba(255, 92, 93, 0.1);
}

.sidebarLink.active .sideImg,
.sidebarLink:hover .sideImg {
  background: #ff5c5d;
}

.sidebarLink.active .sideLogoImg,
.sidebarLink:hover .sideLogoImg {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(300%)
    contrast(103%);
}

.sidebarLink.active .sideLinkText {
  color: #ffffff;
}

.sideListHead {
  margin: 10px 0 0 0 !important;
  padding: 0 !important;
}

.sideListSub {
  list-style: none;
  margin-top: 5px;
}

.sideImg {
  background: #ffffff1a;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ToggleIcon {
  position: absolute;
  right: -20px;
  width: 30px;
  height: 30px;
  border: 1px solid #ff5c5d;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  display: none;
  top: 90px;
}

.mainSidebar.hide h6.sideLinkText {
  display: none;
}

.mainSidebar.hide .sidebarOuter {
  padding: 5px;
}

.mainSidebar.hide .sidebarLink {
  padding: 15px 5px;
  justify-content: center;
  margin: 0 10px;
}

.mainSidebar.hide .brandLink {
  padding: 38px 35px 24px 20px;
}

/*layout css end*/

/*Dashboard css start*/
.dashArticleSub {
  border: 1px solid #ff5c5d;
  border-radius: 20px;
  background-color: #242424;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("../images/dashSecBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.uploadHeading {
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  text-align: left;
  color: #ffffff;
}

.DashArrow {
  background: linear-gradient(131.92deg, #ff5c5d 14.83%, #e43839 84.62%);
  /* width: 60px; */
  max-width: 60px;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 50%;
}

.categoryText {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #9c9c9c;
}

.categoryBox {
  margin-top: 20px;
}

.categoryBx {
  border: 1px solid #ffffff;
  padding: 8px 24px;
  border-radius: 30px;
  color: #ffffff;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.categoryOuterBox {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 14px;
  flex-wrap: wrap;
}

.categoryBx.active,
.categoryBx:hover {
  border: 1px solid #ffbc49;
  background: #ffbc49;
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clearBtn {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  color: #ff5c5d;
  border: none;
  background: transparent;
}

.dashPublishSec {
  margin-top: 24px;
}

.publishText {
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
}

.publicationInfo {
  background: #282828;
  box-shadow: 0px 4px 12px 0px #0000000f;
  padding: 16px;
  border-radius: 14px;
  display: flex;
  align-items: start;
  gap: 12px;
}

.publishImg {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  min-width: 72px;
}

.stateText {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: #ffffff;
  word-break: break-all;
}

.allarticle__ .stateText {
  line-height: 28px;
}

.publishInner {
  width: 88%;
  height: 130px;
}

.ratingText {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.ratingBx {
  display: flex;
  align-items: center;
  gap: 5px;
}

.publishSubInner {
  display: flex;
  align-items: start;
}

.publishLeft {
  border-right: 1px solid #ffffff1a;
  padding-right: 20px;
}

.publishRight {
  padding-left: 20px;
}

.publishLight {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #9c9c9c;
}

.publishFlex {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 9px;
}

.innerHeadText {
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  word-break: break-all;
}

.innerHead {
  display: flex;
  align-items: center;
  gap: 16px;
}

.searchBox {
  position: relative;
  margin: 30px 0;
}

.writeArticleSec .innerHead {
  margin-bottom: 30px;
}

.writeArticleSec .searchBox {
  margin: 0 0 30px;
}

.searchInput {
  border: 1px solid #ffffff4d;
  border-radius: 30px;
  padding: 12px 12px 12px 40px;
  font-family: "Poppins";
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  background-color: transparent !important;
}

.searchInput::placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}

.searchIcnImg {
  position: absolute;
  top: 13px;
  left: 12px;
}

.recentTable {
  border-radius: 30px;
}
.recentTable thead {
  position: sticky;
  top: -1px;
}
.recentHead {
  border: none !important;
  background-color: rgb(47 53 55) !important;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase !important;
}

.recentTable th,
.recentTable td {
  border-bottom: 1px solid #2f3537 !important;
  padding: 24px !important;
}

.recentTable td {
  background-color: rgba(40, 43, 44, 1) !important;
  border-bottom: 1px solid #2f3537 !important;
}

.tableSubText {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  color: #9d9d9d;
}

.namePublish {
  display: flex;
  align-items: center;
  gap: 12px;
}

.publishOuter {
  width: 30px;
  height: 30px;
}

.publishTableImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.recentTable::-webkit-scrollbar:vertical {
  display: none;
}

.recentTable::-webkit-scrollbar:horizontal {
  display: block;
  width: 4px;
  height: 8px;
}

.publishTable .recentSubHead {
  min-width: 130px;
}

.publishTable .recentSubHead:nth-child(2) {
  min-width: 165px;
}

.publishTable .recentSubHead:nth-child(3) {
  min-width: 190px;
}

.publishTable {
  max-height: calc(72vh - 180px);
  overflow-y: auto;
  overflow-x: auto;
}

.recentTable .tableBody tr:last-child .recentSubHead:first-child {
  border-radius: 0 0 0 30px;
}

.recentTable .tableBody tr:last-child .recentSubHead:last-child {
  border-radius: 0 0 30px 0px;
}

.paginateSec {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.paginateSec li {
  color: #fff;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
}

.paginateSec .paginationBox.mb-0 {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 24px;
}

.paginateSec li.selected {
  background: #ff5c5d;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
}

.paginateSec li.previous a,
.paginateSec li.next a {
  color: #fff !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  text-decoration: none;
}

.paginateSec li.next.disabled a,
.paginateSec li.previous.disabled a {
  cursor: not-allowed;
}

.noFoundText {
  background: transparent !important;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.noFoundHead {
  border-radius: 0 0 30px 30px;
}

.deletIcon {
  width: 24px;
  height: 24px;
}

.deleteUpload {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 12px;
  border: none !important;
}

.textInput {
  border: 1px solid #ffffff4d;
  border-radius: 30px;
  padding: 12px 12px 12px 40px;
  font-family: "Poppins";
  font-size: 16px !important;
  font-weight: 400 !important;
  /* color: #ddd5d58b !important; */
  background-color: transparent !important;
}

.textInput::placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}

.nextBtnCommon {
  border: none;
  background: #ff5c5d;
  color: #fff;
  padding: 11px 20px;
  border-radius: 7px;
}

.publication {
  border: 1px solid #ffffff;
  padding: 8px 24px;
  border-radius: 30px;
  background: #ffffff;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  color: #fff;
}

.publication.active,
.publication:hover {
  border: 1px solid #ffbc49;
  background: #ffffff;
  color: #000;
}

.profileLeft {
  background: #131a1c;
  box-shadow: 0px 4px 12px 0px #0000000f;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

/* .profileLeft .uploadSec {
  max-width: 140px;
  width: 100%;
  height: auto ;
} */

.profileRight {
  background: #131a1c;
  box-shadow: 0px 4px 12px 0px #0000000f;
  border-radius: 30px;
  padding: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.profileInner {
  padding: 20px;
  background: #262626;
  border: 2px solid #ff5c5d;
  height: 100%;
  width: 50%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 24px;
  background-image: url("../images/dashSecBg.png");
  background-repeat: no-repeat;
  background-size: 85% 100%;
  background-position: right;
  cursor: pointer;
}

.profileInner.active {
  background-color: #ff5c5d;
  background-image: none;
}

.profileInner.active .DashArrow {
  background: #ffffff;
}

.profileInner.active .dashArrowImg {
  filter: invert(57%) sepia(39%) saturate(1855%) hue-rotate(323deg)
    brightness(114%) contrast(115%);
}

.profileInfoSec {
  margin: 16px 0;
}

.profileSubInfo {
  margin: 24px 0;
  background: #131a1c;
  box-shadow: 0px 4px 12px 0px #0000000f;
  padding: 40px;
  border-radius: 30px;
}

/* .profileSec .phoneControl .react-tel-input .flag-dropdown {
  left: -18%;
} */

.modal-dialog {
  height: 80vh;
  display: flex;
  align-items: center;
}

#deletCategoryModal .modal-content,
#paymentModal .modal-content {
  border: 1px solid rgba(42, 42, 42);
  background: rgb(41 41 41 / 112%);
  border-radius: 30px;
  padding: 0 !important;
  /* margin: 25px; */
}

#deletCategoryModal,
#paymentModal {
  /* width: 100%; */
  max-width: 750px;
}
#paymentSuccess {
  max-width: 750px;
}
/* #paymentModalSuccess .modal-header {
  position: relative;
  justify-content: center !important;
  border-bottom: none !important;
} */
#deletCategoryModal .modal-header,
#paymentModal .modal-header {
  position: relative;
  padding: 40px !important;
  justify-content: center !important;
  border-bottom: 1px solid rgba(47, 53, 55, 1) !important;
}
#paymentModal .modal-header {
  border-bottom: none !important;
}

.modal_cancel {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.modal-header .btn-close {
  display: none;
}

#deletCategoryModal .modal-body,
#paymentModal .modal-body,
#addUrl .modal-body {
  padding: 24px !important;
}

.modal_Heading {
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.articleSelect .displayBlock {
  display: none !important;
}

.articleSelect {
  position: relative;
}

/***Priyanka Css Article Module**/

.dashArticle .innerHead {
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
}

.innerHeadleft {
  display: flex;
  align-items: center;
  gap: 9px;
}

.articleSubcategory_ {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.articlePublication_ {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  /* align-items: start; */
}

.publicationMedia_ {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.mediaInner_ {
  padding: 0 26px;
  position: relative;
}

.mediaInner_::after {
  content: "";
  width: 1px;
  height: 43px;
  position: absolute;
  background: #ffffff33;
  right: 0;
}

.mediaInner_:last-child::after {
  display: none;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
}

.articleImage {
  display: inline-block;
  width: 100%;
  /* max-height: 545px; */
  /* height: auto; */
  border-radius: 20px;
  max-width: 100%;
}

.ckView.row ol,
.ckView.row ul,
.contentPreview_ .row ol,
.contentPreview_ .row ul {
  margin-left: 2rem;
}

.dashArticle .form_subheading {
  text-align: left;
  line-height: 26px;
}

.preImage_ {
  width: 100%;
  height: auto;
}

.secondaryBtb_ {
  background: transparent;
  border: 2px solid #ff5c5d;
  padding: 16px 32px;
  border-radius: 30px;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
}

.dashArticle .signUpBtn {
  border: 2px solid transparent;
}

.mediaInner_ img {
  width: 40px;
  height: 40px;
  border-radius: 80px;
  /* background: #e9ecef;
  padding: 2px; */
}

#scheduleModal {
  max-width: 599px;
  width: 100%;
}

#UnderreviewModal {
  max-width: 600px;
  width: 100%;
}

#UnderreviewModal .modal-content {
  border-radius: 20px;
}

.underreviewmodal_ {
  padding: 30px 20px;
  text-align: center;
}

.schedulecontent_ {
  text-align: center;
  padding: 20px;
}

.selectbox_ {
  border: 1px solid #ffffff4d;
  background: #ffffff1f;
  padding: 16px 24px;
  border-radius: 30px;
  appearance: none;
  position: relative;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  background-image: url(../images/selectArrow.svg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 40px 6px;
  cursor: pointer;
}

.selectbox_:focus-visible {
  outline: none;
}

.selectbox_ option {
  color: #000;
}

.articleSelect .searchWrapper input {
  margin: 0;
  color: #fff;
}

.articleSelect .searchWrapper {
  border: none;
  height: auto !important;
}

.articleSelect .deleteUpload {
  top: 15px;
  right: 15px;
  line-height: 32px;
  width: 30px;
  height: 30px;
}

.deleteUpload i {
  color: #ff5c5d;
  font-size: 16px;
}

.customInput {
  border: 1px solid #ffffff4d !important;
  background: #ffffff1f !important;
  font-size: 16px !important;
  border-radius: 30px;
  padding: 16px 14px !important;
  color: #fff !important;
  width: 100%;
}

.customInput::placeholder {
  color: #fff !important;
}
.deleteInput {
  border: 1px solid #ffffff4d !important;
  background: #ffffff1f !important;
  font-size: 16px !important;
  border-radius: 30px;
  padding: 16px 14px !important;
  color: #fff !important;
  width: 100%;
}

.deleteInput::placeholder {
  color: #fff !important;
}
.deleteAccountfield {
  text-align: center;
}

.writeArticle_ {
  background: #ffffff1f;
  padding: 12px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  text-align: center;
}

.uploadbtntext {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
}

.ColoredText {
  color: #ff5c5d;
}

.uploadText {
  font-size: 14px;
  font-weight: 400;
  color: #bbbaba;
  margin-top: 3px !important;
}

.articleeditor_ {
  margin: 30px 0 !important;
}

.articleeditor_ .cke_inner {
  background: #ffffff1f;
  border-radius: 20px;
}

.articleeditor_ .cke_top {
  border-radius: 20px 20px 0 0px;
  padding: 16px;
}

.articleeditor_ .cke_bottom {
  padding: 8px 8px 10px;
  border-radius: 0px 0px 20px 20px;
  border-top: 1px solid transparent;
  background: #7f7f7f !important;
}

.articleeditor_ .cke_chrome {
  border: 1px solid #ffffff4d !important;
  border-radius: 20px;
}

.articleeditor_ .cke_editable_themed {
  background: #ffffff1f !important;
}

.articleeditor_ .cke_editable {
  background: #ffffff1f !important;
}

.articleeditor_ .cke_path a {
  color: #fff;
}

.uploadedArticle {
  padding: 0;
}

.uploadedArticle .deleteUpload {
  top: -5px;
  right: -22px;
}

.contentPreview_ p {
  color: #fff;
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 400;
  /* text-align: center; */
  word-wrap: break-word;
}

.contentPreview_ li {
  color: #fff;
  margin: 0 0 5px;
  font-size: 16px;
  font-weight: 400;
}

.contentPreview_ h1 {
  color: #fff;
  margin: 0 0 8px;
  font-size: 52px;
  font-weight: 600;
}
.contentPreview_ h2 {
  color: #fff;
  margin: 0 0 8px;
  font-size: 40px;
  font-weight: 600;
}
.contentPreview_ h3 {
  color: #fff;
  margin: 0 0 8px;
  font-size: 30px;
  font-weight: 600;
}
.contentPreview_ h4 {
  color: #fff;
  margin: 0 0 8px;
  font-size: 24px;
  font-weight: 600;
}
.contentPreview_ h5 {
  color: #fff;
  margin: 0 0 8px;
  font-size: 20px;
  font-weight: 600;
}
.contentPreview_ h6 {
  color: #fff;
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
}
.contentPreview_ div {
  background: transparent !important;
  color: #ffffff;
  border-radius: 5px;
  /* margin-top: 5px; */
}

.articleSelect .searchWrapper .chip {
  background: transparent;
  border: 1px solid #ffffff;
  padding: 6px 16px;
  border-radius: 100px;
  margin: 0 9px 0px 0;
  font-size: 14px;
  font-weight: 500;
  gap: 6px;
}

.contentPreview_ p img {
  width: auto;
  height: 600px;
  margin-bottom: 10px;
  object-fit: contain;
}

.schedulecontent_ .react-datepicker-wrapper {
  width: 100%;
}

.schedulecontent_ .react-time-picker__wrapper {
  border: transparent;
}

.schedulecontent_ .react-time-picker__inputGroup {
  text-align: left;
}

.schedulecontent_ .react-time-picker__button svg {
  display: inherit;
  filter: invert(52%) sepia(68%) saturate(4251%) hue-rotate(326deg)
    brightness(101%) contrast(106%);
}

.schedulecontent_ .react-time-picker__button {
  outline: none !important;
}

.schedulecontent_ .react-datepicker__input-container:after {
  content: "";
  background-image: url(../images/calenderimg.png);
  width: 30px;
  height: 30px;
  position: absolute;
  top: 18px;
  background-repeat: no-repeat;
  right: 9px;
  cursor: pointer;
}

.schedulecontent_ .react-time-picker__inputGroup__input {
  outline: none !important;
}

.schedulecontent_ .react-time-picker__inputGroup__input option {
  color: #000 !important;
}

/* ticket css */
/* .TicketDetailSec.content-wrap {
  height: calc(100vh - 110px) !important;
  overflow-y: scroll;
  padding: 16px 24px;
} */


.TicketDetailSec .userDetailRight {
  display: flex;
  align-content: center;
  justify-content: right;
}

.ticketDetailOuter {
  border-radius: 30px;
  margin-top: 40px;
}

.chatOuter {
  border: 1px solid #ffffff4d;
  background: #ffffff1f;
  border-radius: 16px;
  margin-top: 24px;
  /* position: relative; */
  /* height: 680px; */
}

.chatInner {
  /* height: calc(87vh - 200px); */
  /* overflow-y: scroll; */
  padding: 12px;
  /* padding-bottom: 120px; */
}

.chatLeftOuter {
  display: flex;
  align-items: center;
  justify-content: start;
}

.secondPersonChat {
  display: flex;
  align-items: start;
  gap: 12px;
  width: 50%;
  margin-top: 8px;
}

.message_Img_Upload {
  max-width: 200px;
  width: 100%;
  object-fit: contain;
}

.secondPersonImg {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  margin: 0;
}

.secondPersonImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.userText {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.messageBox {
  background: #131a1c66;
  padding: 12px 24px;
  border-radius: 12px 12px 12px 0;
  margin: 8px 0;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  word-break: break-all;
}

.timeText {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  color: #9c9c9c;
}

.chatRightOuter {
  display: flex;
  align-items: center;
  justify-content: end;
}

.firstPersonChat {
  display: flex;
  align-items: start;
  gap: 12px;
  width: 50%;
  margin-top: 8px;
  justify-content: end;
}

.chatBottom {
  background: linear-gradient(253.83deg, #000000 -0.44%, #313131 100%);
  border-radius: 0 0 16px 16px;
  padding: 24px;
  /* margin-top: 16px; */
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: column;
  /* position: absolute;
  bottom: 0;
  width: 100%; */
}

.searchSection {
  position: relative;
}

.tickettext_ {
  font-size: 16px;
  color: #9c9c9c;
  font-weight: 400;
}

.tickettext_ span {
  color: #fff;
  font-weight: 500 !important;
}

.ticketInformation_ {
  display: flex;
  align-content: center;
  gap: 30px;
  /* padding: 0 20px; */
}

.crossBtnCategory {
  /* background: linear-gradient( 101.17deg, #1eeaff 0%, #6e38ff 49.5%, #f600ff 100% ); */
  background: #ff5c5d;
  width: 58px;
  height: 52px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockbtn {
  background: #ff5c5d;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 16px 32px;
  border-radius: 30px;
  border: 2px solid #ff5c5d;
  text-transform: capitalize;
}

.attachIcon {
  position: absolute;
  top: 20px;
  right: 90px;
  cursor: pointer;
}

.TicketDetailSec .searchInput {
  padding: 18px 60px 18px 24px !important;
}

.personInfo {
  width: fit-content;
}

.firstPersonChat .messageBox {
  background: #f2f3f833;
}

.profileImg_ {
  border-radius: 14px;
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.profile-outer {
  width: 28px;
  height: 28px;
}

.nameProfile {
  display: flex;
  align-items: center;
  gap: 6px;
}

.editBtn {
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  background-color: transparent;
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 30px;
}

.recentTable tbody tr:last-child .recentSubHead:first-child {
  border-radius: 0 0 0 30px;
}

.recentTable tbody tr:last-child .recentSubHead:last-child {
  border-radius: 0 0 30px 0px;
}

.tableSubText {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  color: rgba(157, 157, 157, 1);
  margin: 0;
  text-transform: capitalize;
}

.dashboardTable .recentHead:last-child,
.userRecentTable .recentHead:last-child {
  min-width: 100px;
}

.dashboardTable .recentHead:first-child,
.userRecentTable .recentHead:first-child {
  min-width: 100px;
}

.userListStatus {
  display: flex;
  align-items: center;
  gap: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 24px;
}

.pendingTkt {
  background: #ffbc49;
  border: 1px solid #ffbc49;
  padding: 8px 32px;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.completeTkt {
  background: #1bae21;
  border: 1px solid #1bae21;
  padding: 8px 24px;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.rejectTkt {
  background: #ff5c5d;
  border: 1px solid #ff5c5d;
  padding: 8px 24px;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.mainHead32 {
  font-size: 32px;
  font-weight: 600;
  color: #fff !important;
}

.searchBarIcon {
  position: absolute;
  left: 26px;
  top: 20px;
}

.recentUserSec {
  margin: 40px 0 0 0;
}

.recentTable {
  border-radius: 30px;
}

.recentTable th,
.recentTable td {
  border-bottom: 1px solid rgba(38, 41, 42, 1) !important;
  padding: 20px !important;
}

.recentTable td {
  background-color: rgba(19, 26, 28, 1) !important;
}

.recentTable tr:last-child td {
  border-bottom: none !important;
}

.flexDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.seeBtn {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #ff5c5d;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
}

.statusHeading {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: rgba(174, 174, 174, 1);
  padding: 12px;
  position: relative;
  cursor: pointer;
}

.statusHeading.active::before,
.statusHeading:hover::before {
  content: "";
  position: absolute;
  top: 98%;
  width: 100%;
  left: 0;
  height: 3px;
  border-radius: 2px;
  background: #ff5c5d;
}

.statusHeading.active,
.statusHeading:hover {
  color: rgba(255, 255, 255, 1);
}

.textarea_ .form-control {
  height: 140px;
  border-radius: 20px;
  resize: none;
}

.articleInfo_ {
  background: #131a1c;
  border-radius: 30px;
  padding: 10px 15px 10px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.articleInfo_ .stateText {
  font-weight: 700;
  font-size: 21px;
}

.stateTextpara {
  font-size: 13px;
  font-weight: 400;
  color: #9d9d9d;
  display: inline-block;
  width: 87%;
  height: 57px;
  overflow: hidden;
}

.articleInfo_ .tableSubText {
  text-align: right;
}

.allarticle__ {
  height: calc(100vh - 107px);
  margin-top: 0;
}

.ticketTable {
  max-height: calc(85vh - 245px);
  overflow-y: scroll;
}

.ticketTable .recentHead {
  width: 180px;
  min-width: 180px;
}

/* .ticketTable .recentHead:first-child {
  min-width: 130px;
}

.ticketTable .recentHead:nth-child(4) {
  width: 400px;
  min-width: 400px;
}

.ticketTable .recentHead:nth-child(5) {
  width: 200px;
  min-width: 200px;
} */

.articlependingTkt {
  background: #ffbc49;
  border: 1px solid #ffbc49;
  padding: 6px 14px;
  border-radius: 10px;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 131px;
  width: 100%;
  height: fit-content;
}

.contentPreview_ h1 {
  color: #fff;
}

.contentPreview_ ul li,
.contentPreview_ ol li {
  color: #fff;
}

.contentPreview_ h2 {
  color: #fff;
}

.contentPreview_ ul {
  margin: 16px 0;
}

/***Start Subscription Page css ***/
.subscriptionBanner_ {
  display: inline-block;
  width: 100%;
  padding: 0 0 100px;
}

.subscriptionPlan_ {
  background-image: url("../images/plansBg_.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
  border: 1px solid #902e3f;
  padding: 26px;
  height: 100%;
  position: relative;
}

.subscriptionPlan_ .innerHeadText {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #393939;
  margin-bottom: 18px;
}

.subgreyText_ {
  font-size: 14px;
  font-weight: 600;
  color: #939191;
}

.subscriptionUl_ {
  display: inline-block;
  width: 100%;
  margin: 14px 0;
  margin-bottom: 60px;
}

.subscriptionList_ {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 6px;
  line-height: 25px;
  list-style: none;
  position: relative;
}

.currentButton_ {
  background-color: #4c2b2b;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 16px 32px;
  width: 100%;
  border-radius: 30px;
  outline: none;
}

.subscriptionList_:before {
  content: "";
  position: absolute;
  background-image: url(../images/planscheklist.png);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  top: 6px;
  left: -29px;
}

.subscriptText {
  color: #b2aaaa;
  font-size: 20px;
  font-weight: 600;
}

.planhead_ .innerHeadText {
  border: none;
  margin-bottom: 11px;
}

.planhead_ {
  border-bottom: 1px solid #393939;
  margin-bottom: 13px;
}

.planTabs_ {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 100px;
  padding: 0;
}

.planttabslist {
  background: #fff;
  padding: 10px 16px;
  border-radius: 100px;
  list-style: none;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.planttabslist.active {
  background: #ff5c5d;
  color: #fff;
  cursor: pointer;
}

.subscriptionPlan_ .secondry_btn {
  border: none;
  width: 100%;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 200px;
}

.comparePlans_ {
  display: inline-block;
  width: 100%;
  padding: 50px 0;
  background: linear-gradient(
    101.17deg,
    rgb(255, 92, 93, 6%) 0%,
    rgb(255, 188, 73, 6%) 100%
  );
}

/***End Subscription page Css ***/

/***Start About Page Css ***/

.aboutBanner_ {
  display: inline-block;
  width: 100%;
  padding: 0px 0;
}

.about_Us_Banner {
  padding-bottom: 100px;
}

.aboutHead_ {
  font-size: 100px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.2);
  line-height: 150px;
}

.aboutPara_ {
  font-size: 48px;
  font-weight: 500;
  color: #fff;
  max-width: 1114px;
  width: 100%;
  line-height: 60px;
}

.aboutrightimg_ {
  width: 100%;
}

.aboutRightimage_ {
  z-index: 9;
  /* position: relative; */
  position: absolute;
}

.aboutLeftcontent_ .sub_p_text {
  max-width: 391px;
  width: 100%;
}

.articleRules_ {
  display: inline-block;
  width: 100%;
  padding: 10% 0 5%;
  background: #211919;
  position: relative;
  /* top: -137px; */
}

.mainRules_ {
  /* border: 3px solid #903a3b;
  border-radius: 30px; */
  background-image: url("../images/border_Img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 40px 50px;
  text-align: center;
  position: relative;
  margin-top: 40px;
}

.head24_ {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  color: #fff;
}

/* .mainRules_:before {
  content: "";
  position: absolute;
  width: 416px;
  height: 256px;
  border: 3px solid #4d2627;
  left: -14px;
  top: -14px;
  border-radius: 30px;
} */

.chooseOption_ .authSubText {
  text-align: left;
  position: relative;
}

.chooseOption_ .sub_p_text {
  color: #e5e5e5cc;
}

.chooseOption_ .authSubText:before {
  content: "";
  width: 90px;
  height: 3px;
  position: absolute;
  background: #fff;
  bottom: -12px;
}

.whyChoose_ {
  background: linear-gradient(
    359.39deg,
    #131a1c -1.26%,
    rgba(19, 26, 28, 0.7) 34.89%,
    #030305 79.98%
  );
  padding: 60px;
  display: inline-block;
  width: 100%;
}

.aboutgrowth .growth_box,
.aboutgrowth .time_Saved {
  text-align: center;
}

.aboutgrowth .time_saved_txt,
.aboutgrowth .time_Saved .time_saved_txt {
  display: inline-block;
  width: 100%;
}

.time_Saved .time-saved-txt {
  display: inline-block;
  width: 100%;
}

.aboutgrowth .time_Saved {
  padding: 34px 0;
}

.editBtn_ {
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  background-color: transparent;
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 30px;
}

.categoryOuterBtn {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
}

.delete_btn {
  border: 1px solid #ff5c5d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  background-color: transparent;
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 30px;
}

.chatInner_Parent {
  height: calc(100vh - 510px);
  overflow-y: scroll;
}

.chatInner_Parent::-webkit-scrollbar {
  display: none;
}

/* Chnages By Rohit */
.ronand_Txt {
  margin-top: 20px;
}

/* Start Privacy & Policy */
.privacy_Page {
  background: linear-gradient(253.83deg, #000000 -0.44%, #313131 100%);
}

.privacy_Lastdate {
  margin-top: 32px;
  color: #aeaeae !important;
}

.privacy_Contentlist {
  color: #aeaeae !important;
}

.privacy_Contentlist:hover {
  color: #fff !important;
}

.contents_Table {
  padding: 40px 40px 20px 40px;
  background: #ffffff1a;
  border-radius: 20px;
}

.privacy_table_ul {
  margin-top: 24px;
}

.privacy_table_ul li {
  padding: 0px 5px 16px;
  list-style: decimal;
}

.privacy_Txt_Parent {
  margin-bottom: 60px;
}

/* .table_Content_Summary {
  margin-left: 64px;
} */

/* .table_Content_Summary ul li {
  list-style: decimal;
  padding: 0px 5px 36px;
} */

/* End Privacy & Policy */

/* Subscription Page Start */
.signUpBtn.upgrade_Subcription_Btn {
  max-width: 295px !important;
}

.subcription_Plan_Price {
  padding-top: 32px;
}

.subcription_Plan_Month {
  color: #aeaeae !important;
}

.next_Subs_Payment {
  padding-top: 24px;
  color: #aeaeae !important;
}

.payment_Date {
  font-weight: 600 !important;
  color: #ffffff;
}

.current_Plan_Parent {
  margin-bottom: 40px;
}

.includes_Parent {
  border-top: 1px solid #ffffff;
  opacity: 20%;
}

.subscription_Includes {
  color: #939191 !important;
  font-weight: 600 !important;
  margin: 32px 0px 15px;
}

/* Transaction Button */
.transaction_Prev_Btn {
  padding: 0 10px;
  border-radius: 50%;
  background: rgba(255, 92, 93, 1);
  color: white;
}

.arrowLink_Img {
  position: absolute;
  left: 50px;
  padding: 8px 14px;
  border-radius: 50%;
  background: rgba(255, 92, 93, 1);
}

.currentplan_Btn {
  background-color: #4c2b2b !important;
}

.marker {
  background-color: rgb(165, 165, 55);
  /* Or any color you prefer */
  font-weight: bold;
  /* Optional: make the text bold */
}

.checkout_Pay_Btn {
  background: rgba(255, 92, 93, 1);
  color: #fff;
  padding: 17px 50px;
  border: none;
  border-radius: 15px;
}

.notifyLoader {
  height: 115px !important;
  justify-content: center !important;
}

.planTable::-webkit-scrollbar {
  display: none;
}

.planInnerHead .arrowLink_Img {
  position: static;
  margin-top: 12px;
}

.planInnerHead {
  display: flex;
  align-items: start;
  gap: 24px;
}

.publishInner .stateText {
  width: 90%;
  margin-right: 10px;
}

.namePublish .tableSubText {
  width: 85%;
}

.publishTable .recentSubHead:first-child {
  min-width: 280px;
}

.MuiFormControl-root {
  width: 100%;
  background: #ffffff1f;
  border-radius: 30px;
  border: 1px solid #ffffff4d !important;
}

.customSelect .MuiOutlinedInput-notchedOutline {
  border: none;
}

.customSelect:hover .MuiOutlinedInput-notchedOutline {
  border: none;
}

.customSelect.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none;
}

.customSelect .MuiSelect-root {
  border-top: none;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.customSelect {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}

.customSelect .css-1km1ehz.Mui-selected {
  background-color: #757575 !important;
}

.cardNumberContainer {
  position: relative;
}

.CardNumberField-input-wrapper {
  left: 0 !important;
}

.CardBrandIcon-container {
  right: 0 !important;
}

.clientLogoOuter {
  width: 356px;
  height: 118px;
}

/* Hamburger Css */
.dashBar_ {
  background: #ff5c5d;
  border-radius: 8px;
  display: inline-block;
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

/* .sidebarOuter{
  width: 100px;
  position: fixed;
} */
.dashboardMain.hide {
  width: calc(100vw - 110px);
  margin-left: 100px;
}

.dateTimeHead {
  min-width: 150px;
}

.mainSidebar.hide:hover {
  width: 285px;
}

.mainSidebar.hide:hover h6.sideLinkText {
  display: block;
}

.mainSidebar.hide:hover .sidebarLink {
  justify-content: start;
}

.timepicker-outer .rc-time-picker-input {
  border: 1px solid #ffffff4d !important;
  background: #ffffff1f !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: left;
  border-radius: 30px;
  padding: 16px 24px;
  color: #ffffff !important;
  height: 100%;
}

.timepicker-outer {
  width: 100%;
}

.clock-icon {
  position: absolute;
  cursor: pointer;
  right: 17px;
  top: 18px;
}

li.rc-time-picker-panel-select-option-selected {
  background: #ff5c5d !important;
  font-weight: bold;
  color: #fff;
}

.rc-time-picker-panel-select li:hover {
  background: #ff5c5d7d;
  color: #000;
}

.phoneControl .react-tel-input {
  height: 100%;
}

#cropImage {
  max-width: 500px !important;
}

#cropImage .submitBtn {
  border: none;
  background: #069d06;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
  min-width: 150px;
  font-size: 16px;
  font-weight: 600;
}

#cropImage .cancleBtn {
  background-color: #ff5c5d;
}

#cropImage .modal-body {
  padding: 25px;
  max-height: calc(100vh - 227px) !important;
  overflow-y: auto;
}

.corpImage_ {
  height: auto !important;
  max-width: 100% !important;
}

#cropImage .ReactCrop.ReactCrop--fixed-aspect {
  width: 100% !important;
}

.rc-time-picker-panel-select::-webkit-scrollbar {
  width: 1px;
  display: none;
}

/* Track */
.rc-time-picker-panel-select::-webkit-scrollbar-track {
  background: #888;
  border-radius: 40px;
  /* scroll bar Margin */
  margin: 0 20px !important;
  display: none;
}

/* Handle */
.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 4px;
  display: none;
}

/* Handle on hover */
.rc-time-picker-panel-select::-webkit-scrollbar-thumb:hover {
  background: #e21e1e;
  display: none;
}

.contentPreview_ figure {
  margin: 20px 0 20px 20px !important;
}

.schedule_Time .rc-time-picker-clear-icon:after {
  content: none;
}

/* Remove input type number arrow */
input[type="number"] {
  -moz-appearance: textfield;
}

.faq_Page {
  padding-bottom: 50px;
}

/* Draft Article Schedule */
.contentPreview_ .ckView h1 {
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
}

.contentPreview_ .ckView h2 {
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
}

.contentPreview_ .ckView h3 {
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
}

.contentPreview_ .ckView h4 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}

.contentPreview_ .ckView p {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  padding-top: 10px;
  word-wrap: break-word;
}
.ckView p {
  word-wrap: break-word;
}
.contentPreview_ .ckView hr {
  display: none;
}

.noNotificationsFound {
  margin-top: 25% !important;
  font-size: 24px !important;
}

/* Start CardPaymentModal Css */
#cardPaymentModal .modal-header {
  padding: 32px 40px 24px;
}
#cardPaymentModal .modal-body {
  padding: 30px 80px;
}
#cardPaymentModal.modal-dialog {
  max-width: 600px;
}
.card_Payment {
  background-image: url("./../images/cardPaymentBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 190px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
}
.card_Payment.active {
  box-shadow: 2px 1px 15px 5px #eeeeee3b;
}

.card_date {
  font-family: Lexend Deca;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  color: #ffffff;
}
.cardNo_Parent .cardNo {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
}
.cardNo_Hidden {
  margin-top: 5px;
  margin-right: 8px;
}
.cardNo_Parent .card_Name {
  color: #ffffff;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  padding-top: 8px;
}
.cardNo_Parent .delete_Logo {
  padding: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
}
.addNewCard_Btn {
  background-color: initial;
  border: 1px solid #ff5c5d;
  border-radius: 30px;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
  min-width: 200px;
  padding: 16px 35px;
  text-align: center;
}
.pay_Btn {
  background-color: #ff5c5d;
}
.card_Brand {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
}
/* End CardPaymentModal Css */

.priceInput {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* Start Checkbox of Publication Page */
.addPublishSec .premiumLabel {
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  color: #ffffff;
}

.addPublishSec .premiumLabel_Parent {
  margin-left: 30px;
}

.addPublishSec .free {
  color: #aeaeae;
}

.publication_Premium [type="radio"]:checked,
.publication_Premium [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.publication_Premium [type="radio"]:checked + label,
.publication_Premium [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  /* color: #666; */
}

.publication_Premium [type="radio"]:checked + label:before,
.publication_Premium [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ff5c5d;
  border-radius: 100%;
  background: transparent;
}

.publication_Premium [type="radio"]:checked + label:after,
.publication_Premium [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #ff5c5d;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.publication_Premium [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.publication_Premium [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* End Checkbox of Publication Page */

.article_Flexbox.flexBox {
  flex-wrap: nowrap;
}
#deleteCardModal .modal-content {
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 2px 1px 13px 1px #eeeeee3b;
}
#deleteCardModal .modal_Heading {
  font-size: 24px;
}
#deleteCardModal .modal-header {
  padding: 40px 40px 30px !important;
}
.dashBoard_Img_Parent {
  width: 80px;
}
.timer_Btn button {
  background-color: transparent !important;
  color: #ff5c5d !important;
}

.timer_Btn span {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #ff5c5d !important;
}
.subcription_Faq {
  margin: 0 !important;
  padding-top: 100px;
  background: linear-gradient(253.83deg, #000000 -0.44%, #313131 100%);
}
.subcription_Main.mainBox {
  height: auto;
}
.dashboardMain.hide.subcriptionLayout {
  height: auto;
}
.dashboardMain.full.subcriptionLayout {
  height: auto;
}

.home_slider_img {
  border-radius: 10px;
  box-shadow: 0px 1px 13px 2px #eeeeee47;
}

.upload_Article_Btn {
  color: rgba(255, 92, 93, 1);
  position: absolute;
  top: 12px;
  right: 10px;
}

.upload_Article_Btn.add_Url_Btn {
  top: 12px;
  right: 200px;
}
.show_Publication {
  margin-bottom: 30px;
}

.target-div {
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease;
}

.target-div.visible {
  opacity: 1;
  margin-bottom: 30px;
}

.select_Publication .target-div.visible {
  margin-bottom: 0 !important;
}

#articleMessageModal {
  max-width: 600px;
}

#articleMessageModal .chatOuter {
  margin-top: 0;
}

#articleMessageModal .searchInput {
  padding: 18px 12px 18px 20px;
}

.chatInner_Parent_new {
  height: 570px;
  overflow-y: auto;
}
#articleMessageModal .searchInput {
  padding: 18px 12px 18px 20px;
}
/*reetu css start*/
.addOnBox {
  background: #131a1c;
  box-shadow: 0px 4px 12px 0px #0000000f;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/*reetu css end*/
.addOnPara {
  color: #9d9d9d;
  font-size: 14px;
  font-weight: 400;
}
.addOnArticleSection .planTabs_ {
  max-width: 200px;
  margin-top: 16px;
}
.addOnSubBox {
  width: 100%;
  /* max-width: 740px; */
}

.addOnSubBoxgooglrUrl {
  width: 100%;
  max-width: 300px;
}
.morePriceTxt {
  color: #ff5c5d !important;
}
.lessPriceTxt {
  color: #1bae21;
}
.addOnDetailBox {
  background: #131a1c;
  box-shadow: 0px 4px 12px 0px #0000000f;
  border-radius: 30px;
  padding: 30px;
}
.uploadInner label input {
  display: none;
}

.uploadInner {
  width: 250px;
  height: 312px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 35px;
  margin: 0;
  background: rgba(255, 255, 255, 0.12);
}
.uploadAddLabel {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  cursor: pointer;
}
.fileImg {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  cursor: pointer;
}

.publishLogoImg {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  cursor: pointer;
  object-fit: cover;
}
.offerDetailBox {
  background: #131a1c80;
  border-radius: 12px;
}

.offerUpper {
  background-color: rgba(47, 53, 55, 0.9) !important;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2f3537;
}
.offerLower {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.totalBox {
  background: #131a1c80;
  border-radius: 12px;
  padding: 16px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.publicationSelect.searchBox.select_Publication_active .publishArrow {
  transform: rotate(180deg);
}
.publicationSelect.active .publishArrow {
  transform: rotate(180deg);
}

.addonDetail_Btn {
  padding: 16px 50px;
}

/* Notification Modal */
#notificationModal.modal-dialog {
  display: block;
  position: fixed;
  right: 20px;
  max-width: 450px;
  width: 100%;
  top: 70px;
}
#notificationModal .modal-content {
  height: calc(100vh - 130px);
}
#notificationModal .notificationModal_Btn {
  padding: 10px 24px;
}
.seeAll_Notification {
  font-size: 18px;
  font-weight: 600;
  color: #ff5c5d;
}
.notifyList.notifyModal_List {
  padding: 15px;
}
.notifyModal_List .unreadBtn {
  margin-right: 0;
}
/* End Notification Modal */

.custom-slider .rc-slider-track {
  background-color: #ff5c5d;
  height: 8px;
}

.custom-slider .rc-slider-handle {
  border: 4px solid #ff5c5d;
  background-color: #fff;
  width: 22px;
  height: 22px;
  margin-top: -7px;
}
.custom-slider .rc-slider-handle:hover {
  border-color: #ff5c5d !important;
}

.custom-slider .rc-slider-handle:active {
  border-color: #ff5c5d;
  box-shadow: 0 0 5px #ff5c5d;
  cursor: grabbing;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #ff5c5d !important;
  box-shadow: none !important;
}
.custom-slider .rc-slider-handle:focus-visible {
  border-color: #ff5c5d !important;
  box-shadow: none !important;
}

.custom-slider .rc-slider-dot {
  background-color: #fff;
  border: 2px solid #fff;
  width: 10px;
  height: 10px;
  top: -3px;
}

.custom-slider .rc-slider-dot-active {
  border-color: #ff5722;
}

.custom-slider .rc-slider-mark-text {
  color: #ffffff;
  font-size: 14px;
}

.custom-slider .rc-slider-rail {
  height: 8px;
}
.max-range-price,
.min-range-price {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.range-prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.searchBarIconDashboard {
  position: absolute;
  left: 14px;
  top: 14px;
}
.dashSel_Region {
  margin-bottom: 14px;
}

/* Article Detail Css */
.articleUpdate_Btn {
  padding: 15px 32px;
  border-radius: 30px;
}
.transTable_Img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
}
.transTable_Img img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.contentPreviewDraftDetail div:first-child {
  background: transparent !important;
  color: #ffffff;
  border-radius: 5px;
}

.view-all-genres {
  color: #ff5c5d !important;
}
.categoryBoxModal {
  border: rgba(255, 92, 93, 0.2);
  background: rgba(255, 92, 93, 0.2);
  padding: 8px 24px;
  border-radius: 30px;
  color: #ffffff;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.add_Url_Txt {
  font-size: 16px;
  color: #fff;
  margin-right: 10px;
}
.articleUrl_show {
  font-size: 18px;
  color: #fff;
}

/* About Us Page */
/* .ourvision_Section {
  padding: 3% 0;
  background-color: #262626;
} */
.ourStory_Section {
  background: linear-gradient(359.39deg, #131A1C -1.26%, rgba(19, 26, 28, 0.7) 34.89%, #030305 79.98%);
}
.subscriptionBuy {
  width: 100%;
  max-width: 309px;
}
.slick-slide img {
  display: block;
  object-fit: cover !important;
}
.teamImages{
  max-width: 320px;
  width: 100%;
}
.subscriptTextHead {
  color: #b2aaaa;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
}
.yearlyDiscount_Txt {
  font-size: 20px;
  color: #fff;
}
/* Start input prefilled webkit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #ffffff !important;
  box-shadow: unset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

input:-moz-autofill {
  color: #ffffff !important;
  box-shadow: none !important;
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
/* End input prefilled webkit */

/* Start Input Label */
.input_Label {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  margin-bottom: 5px;
}
/* End Input Label */

/* Start Article */
.articleDetail_Date {
  position: absolute;
  right: 5px;
  bottom: 2px;
}
.state_Txt {
  white-space: nowrap;
  width: 350px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deleteUpload_active{
  top: 0 !important;
  right: 18px !important;
}
.disabled {
  pointer-events: none; /* Disable interactions */
  opacity: 0.5;         /* Fade out to show it's disabled */
}
/* Ticket Chat Box */
.chatInner_Parent.ticket_ChatInner_Parent{
    height: 525px;
    display: flex;
    flex-direction: column;
}
.articlePreviewImage{
  border-radius: 10px;
}
.article_Review .articleImage{
  height: auto;
  /* max-height: 545px; */
  padding: 0 5px;
  margin-top: 80px;
}
.articleDetail_Fixed {
  position: sticky;
  top: -2px;
  z-index: 1;
  background: #3d3d3d;
  padding-top: 24px;
  padding-bottom: 24px;
}
.content-wrapper.article_Review{
  padding-top: 0;
}
.articleCover_Img{
  border-radius: 12px;
}

/* Changes */
.articleMultiImg_Slider .slick-list{
  margin: 0 10px;
}
.articleSlider_Row{
  margin: 0 50px
}

.contentPreview_ figure img{
  border-radius: 12px;
}

/* AI Form Css */
.formFields_Head{
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 5px;
  margin-top: 30px;
}
/* End  */

/* Start About US Page */
.overvisionSection_Child{
    background: #211919;
    padding: 5% 0;
    border: #211919;
}
.visionImg_Parent{
  max-width: 420px;
}

.ourStory_Box{
  background: #525252AB;
  box-shadow: 6px 5px 8.4px 0px #00000040;
  padding: 20px 48px;
  border-radius: 10px;
}
.ourStory_Para{
  padding: 0 40px;
  text-align: center;
}
.ourStory_Head{
  padding: 0 60px;
  text-align: left !important;
}
.ourStory_Section{
  padding: 5% 0;
}

.theTeam_Container{
  background: #211919;
}
/* .aboutUs_teamParent{
  padding: 3% 0;
} */
.aboutUs_teamParent .ourStory_Section{
  background: #211919;
}
.aboutUs_teamParent{
  background-color: #262626;
}
.aboutUs_Commitment{
  background: #211919;
  padding: 60px 0;
}
.about_GetIntouch{
  background-image: url("../images/getInTouchBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.experience_count .count_Container{
  padding: 30px 0 0;
}
.experience_count .count_Para{
  font-weight: 400;
}
.teamSider{
  margin-top: 70px;
}
.team_Section{
  padding: 5% 0 1%;
}
.aboutStory_Quates{
  height: 30px;
}
.ourStory_Head{
  text-align: center !important;
}
/* End About US Page */

/* Start Article Form Detail */
.aiForm_Detail_Parent{
  padding-top: 20px;
}
.aiForm_Detail_Parent ul{
  padding: 0;
  padding-top: 10px;
}
.aiForm_Detail_Parent .formDetail_List{
 padding-bottom: 8px;
 font-size: 18px;
 font-weight: 600;
 color: #FFF;
 word-wrap: break-word;
}
.aiForm_Detail_Parent .formDetail_List span{
  font-weight: 500;
 }
 .aiForm_Detail_Head{
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #FFF;
 }
 .docfile_Parent{
  max-width: 300px;
  width: 100%;
 }
 .docfile_Img{
  width: 100%;
 }
/* End Article Form Detail */

/* Article Detail Checkbox */
.addOnArticle_Checkbox{
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap ;
}
.addOnArticle_CheckBox:checked {
    background-color: #ff5c5d;
    border-color: #ff5c5d;
}
.addOnArticle_CheckBox:focus {
  border-color: #ff5c5d;
  outline: 0;
  box-shadow: none !important
}
.article_Offer{
color: #FFF;
font-size: 14px;
}

.contentPreviewDraftDetail .easyimage{
text-align: center;
padding: 10px 0;
}

.word_Break{
  word-break: break-all;
}
