@media screen and (max-width: 1600px) {
  /* .profileSec .phoneControl .react-tel-input .flag-dropdown {
    left: -31%;
  } */

  /* .profileSec .phoneControl {
    width: 75%;
  } */

  .profileRight .innerHeadText {
    font-size: 28px;
  }

  .profileRight .DashArrow {
    width: 55px;
    height: 55px;
  }

  .profileInner {
    gap: 18px;
  }
}

@media screen and (max-width: 1365px) {
  .uploadHeading {
    font-size: 24px;
  }

  .dashArticleSub {
    padding: 30px;
  }

  .profileRight .innerHeadText {
    font-size: 26px;
  }

  .profileInner {
    gap: 15px;
  }
}

@media screen and (max-width: 1280px) {
  .subscriptMain .secondry_btn {
    padding: 12px 20px;
  }

  .timeZoneSelect li:nth-child(odd) {
    margin-right: 14px;
  }

  /* .no_Tickets_Txt{
  text-align: start !important;
} */
}

@media screen and (max-width: 1200px) {
  .aiForm_Detail_Parent .formDetail_List {
    font-size: 16px !important;
  }
  .aiForm_Detail_Head {
    font-size: 20px !important;
  }

  .profileRight .DashArrow {
    width: 35px;
    height: 35px;
  }

  .profileRight .innerHeadText {
    font-size: 18px;
  }

  .phoneControl .react-tel-input .flag-dropdown {
    padding: 16px 11px !important;
  }

  .medium_h {
    line-height: 30px;
  }

  .colored_txt {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .getIntouch_Btn {
    margin-top: 25px;
  }

  .ourStory_Head {
    font-size: 30px;
  }
  .ourStory_Head.mb-4 {
    margin-bottom: 0 !important;
    padding-bottom: 12px;
  }
  .ourStory_Para {
    font-size: 15px;
  }
  .aiForm_Detail_Head {
    font-size: 18px !important;
  }

  .state_Txt {
    width: 260px !important;
  }
  aside.mainSidebar.hide {
    display: none;
  }

  .nav-link.dashBar_.webBar {
    display: none;
  }

  .nav-link.dashBar_.mobileBar {
    display: flex;
  }

  .dashboardMain {
    width: 100%;
    margin-left: 0;
  }

  .mainSidebar.hide {
    display: block;
    width: 285px;
  }

  .mainSidebar.hide .sidebarLink {
    justify-content: start;
  }

  .mainSidebar.hide h6.sideLinkText {
    display: block;
  }

  .dashboardMain.hide {
    width: 100%;
    margin-left: 0;
  }

  .bellBtn {
    width: 45px;
    height: 45px;
  }

  .countValue {
    width: 13px;
    height: 13px;
    right: 2px;
    top: -2px;
  }

  .navLeft {
    gap: 7px;
  }

  .profileText {
    font-size: 20px;
  }

  .ToggleIcon {
    display: block;
  }

  /* .dashboardMain {
    width: calc(100vw - 100px);
    margin-left: 100px;
    height: 100vh;
  } */

  .innerHeadText {
    font-size: 28px;
  }

  .signupField_LastName {
    padding-left: 0 !important;
  }

  .aboutUs_Slider_Marque {
    margin: 20px 0;
  }

  .phoneControl .react-tel-input .flag-dropdown {
    padding: 16px 14px !important;
  }

  .sidebarOuter {
    height: calc(100vh - 161px);
  }
}

@media screen and (max-width: 767px) {
  /* Dashboard */
  .publicationInfo {
    flex-wrap: wrap;
  }
  .publishInner {
    width: 100%;
  }
  .publishSubInner {
    justify-content: space-between;
  }
  .publishInner {
    height: unset;
  }
  /* End Dashboard */
  .textCenter {
    text-align: center;
  }
  .joinTeam_Right {
    padding-top: 30px;
  }
  .getIn_Touch_Parent {
    padding: 70px 0px;
  }
  .visionImg_Parent {
    max-width: 100%;
    text-align: center;
  }
  .aboutUs_Commitment {
    padding: 60px 0;
  }
  .about_OurValues {
    padding: 50px;
  }

  .ourStory_Head {
    padding: 0;
  }
  .ourStory_Para {
    text-align: left;
  }
  .vision_Divider {
    order: 2;
    margin-top: 30px;
  }
  .vision_SecondDivider {
    order: 1;
  }
  .articleMultiImg_Slider {
    padding-bottom: 50px;
  }

  .articleMultiImg_Slider .slick-arrow.slick-next {
    position: absolute;
    top: unset !important;
    bottom: -20%;
    right: 0;
    left: 50px !important;
    margin: auto;
  }
  .articleMultiImg_Slider .slick-arrow.slick-prev {
    position: absolute;
    top: unset !important;
    bottom: -20%;
    right: 50px;
    left: 0 !important;
    margin: auto;
  }
  .allarticle__ .raise_Ticket_Btn {
    padding: 10px 15px;
    font-size: 14px;
    display: inline !important;
  }

  .addOnArticleSection .articleCover_Img {
    margin-top: 15px;
  }
  .upload_Article_Btn {
    position: unset;
    margin-top: 12px;
    border-radius: 30px;
    background: #ff5c5d;
    border: none;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff !important;
  }
  .article_StatusBtn {
    padding: 8px 18px;
  }
  .publication_Media_Resp {
    margin-top: 20px;
  }

  .profileSec .profileRight {
    padding: 30px;
  }

  .mediaInner_::after {
    content: none;
  }

  .profileSec .signupField.mt-3 {
    margin-top: 0 !important;
  }

  .profileSec .profileInner {
    padding: 14px 20px;
  }

  .addPublishLeft .searchBox {
    margin: 20px 0 0 !important;
  }

  .article_Publication_Resp {
    display: block;
  }

  .article_Publication_Resp .mediaInner_ {
    padding: 0;
  }

  #scheduleModal {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }

  .contactBox {
    max-width: 570px;
    padding: 24px;
  }

  #deletCategoryModal .modal-header,
  #paymentModal .modal-header {
    /* padding: 24px !important; */
  }

  .authLogoImg {
    margin: 25px;
  }

  .authInnerSec {
    padding: 36px 24px;
  }

  .mainNavbar {
    padding: 12px 23px 12px 24px;
  }

  .userProfileImg {
    width: 55px;
    height: 55px;
  }

  .bellBtn {
    width: 40px;
    height: 40px;
  }

  .bellBtn img {
    width: 23px;
    height: 23px;
  }

  .navRight {
    /* justify-content: space-between; */
    margin-top: 12px;
    padding-bottom: 10px;
  }

  /* .mainSidebar.hide {
    width: 85px;
  } */

  /* .dashboardMain {
    width: calc(100vw - 85px);
    margin-left: 85px;
    height: 100vh;
  } */

  .mainNavbar {
    padding: 12px 23px 5px 24px;
  }

  .content-wrapper {
    margin-right: 12px;
    padding: 23px;
  }

  .sideHeading {
    font-size: 14px;
    margin-left: 6px;
  }

  .sideImg {
    width: 36px;
    height: 36px;
  }

  /* .mainSidebar.hide .sideListSub {
    width: 50px;
  } */

  .mainSidebar.hide .sideListHead {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }

  .sidebarInner {
    margin-top: 20px;
  }

  .stateText {
    font-size: 16px;
  }

  .dashArticleSub {
    padding: 25px;
  }

  .DashArrow {
    width: 50px;
    height: 50px;
  }

  .categoryBx {
    padding: 6px 12px;
    font-size: 12px;
  }

  .content-wrapper {
    height: calc(100vh - 121px);
  }

  .handImg {
    display: none;
  }

  .profile_Txt {
    display: none;
  }

  .noFoundText {
    /* text-align: start !important; */
  }

  .upgradeSubcription_Content .signUpBtn.upgrade_Subcription_Btn {
    padding: 16px 18px;
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  .aiAddOn_Statetxt{
    width: 100% !important;
  }
  .contentPreview_ ol{
    margin-left: 0 !important;
  }
  .userArticle_Image{
    margin-top: 25px !important;
  }
  .ourVision_Btn {
    text-align: center;
  }
  .contentPreview_ .ckView h2 {
    font-size: 20px;
}
.contentPreview_ .ckView p {
  font-size: 14px;
}
  .getIntouch_Btn {
    margin-top: 30px;
  }
  .visionImg_Parent {
    max-width: 100%;
  }

  .about_OurCommitHead {
    font-size: 30px;
    line-height: 40px;
  }
  .count_Container {
    padding: 40px 0 !important;
  }
  .joinOur_Team {
    padding: 40px 0;
  }
  .aboutUs_Commitment {
    padding: 40px 0;
  }
  .ourStory_Box {
    padding: 20px !important;
  }
  .aiForm_Detail_Parent {
    padding-top: 10px;
  }
  .aiForm_Detail_Parent ul {
    padding-top: 0;
  }
  .aiFormUploadImg {
    width: 100%;
  }
  .articleDetail_Fixed {
    position: unset !important;
    padding-bottom: 0;
  }
  .aiForm_Detail_Head{
    padding-bottom: 10px;
  }
  .boxes_new_section .box_heading_button {
    margin-bottom: 5px;
  }
  .articleImgBox_Parent{
    display: flex !important;
    justify-content: center !important;
  }
  .boxes_new_section .box_img {
    text-align: center;
    margin-bottom: 15px;
  }
  .boxes_new_section .box_img img{
    object-fit: unset !important;
  }
  .boxes_new_section .main_box_artical {
    padding: 10px !important;
  }
  .boxes_new_section .main_box_artical .col-sm-3 {
    padding-right: 0 !important;
  }
  .boxes_new_section .box_img img {
    max-width: 100% !important;
    object-fit: cover;
    height: 140px !important;
  }
  .client_Logoes img {
    height: 190px !important;
  }
  .allarticle__ .flexBox {
    gap: 15px;
  }

  .addOnArticleSection .stateText {
    width: 100% !important;
    line-height: 26px;
  }

  .contentPreview_ h1 {
    font-size: 22px;
  }
  .contentPreview_ h2 {
    font-size: 20px;
  }
  .contentPreview_ h3 {
    font-size: 18px;
  }
  .contentPreview_ h4,
  h5,
  h6 {
    font-size: 16px;
  }

  .innerHead {
    gap: 10px;
  }
  .pointCursor {
    height: 16px;
  }
  .writeArticleSec .articleeditor_ {
    margin: 30px 0 20px !important;
  }
  .userListStatus.dashboard_Userlist {
    margin-bottom: 0;
  }
  .dashboard_Section .dashPublishSec {
    margin-top: 12px;
  }
  .categoryOuterBox {
    margin-top: 8px;
  }
  .completeTkt {
    font-size: 11px;
  }

  /* .articleimageinfo_ {
    height: 150px;
  } */
  .home_Banner {
    padding-bottom: 0;
  }

  .why_Choose_Row {
    margin-top: 10px !important;
  }

  .aboutUs_Slider_Marque {
    margin-top: 25px !important;
  }

  .aboutgrowth {
    padding: 25px 0;
  }

  .why_Choose {
    padding: 30px 0;
  }

  .slidebar {
    margin: 10px 0 10px 0;
  }

  .frequently {
    padding: 0px 0px 30px;
  }

  .TicketDetailSec .timeText {
    font-size: 10px;
  }

  /* .articleeditor_ .cke_inner.cke_reset {
    height: 400px;
  } */

  .articleeditor_ .cke_reset {
    background: #a9a9a912 !important;
    height: unset !important;
  }

  .subscriptionBanner_ {
    padding: 0 0 30px;
  }

  .crossBtnCategory {
    width: 49px;
    height: 38px;
  }

  .crossBtnImg {
    height: 25px;
    width: 24px;
  }

  .planTable.table-responsive.my-5 {
    margin: 20px 0 0 !important;
  }

  .subscriptionBanner_ .comparePlans_ {
    padding: 30px 0;
  }

  .home_Growth .time_Saved {
    margin: 30px 10px 30px 28px;
  }

  .home_Growth .growth_box {
    margin: 30px 10px 30px 28px;
  }

  .TicketDetailSec .searchInput {
    padding: 12px 55px 12px 12px !important;
  }

  .TicketDetailSec .attachIcon {
    right: 70px;
    top: 14px;
  }

  .ticketInformation_ {
    flex-wrap: wrap;
    gap: 20px;
  }

  .userDetailRight.ticket_DetailRight {
    justify-content: start;
    margin-top: 20px;
  }

  .userDetailRight.ticket_DetailRight .blockbtn {
    padding: 10px 20px;
  }

  .update_Draft_Btn .blockbtn {
    padding: 7px 19px;
    font-size: 14px;
  }

  .update_Draft_Btn .secondaryBtb_ {
    padding: 7px 19px;
    font-size: 14px;
  }

  .update_Draft_Btn .blockbtn.me-3.mt-5 {
    margin-top: 8px !important;
  }

  .update_Draft_Btn .secondaryBtb_.me-3.mt-5 {
    margin-top: 8px !important;
  }

  .modal_cancel {
    top: 14px;
    right: 22px;
  }

  .CartItemBox {
    padding: 20px;
  }

  .upgradeSubcription_Btn {
    justify-content: start !important;
  }

  .article_Review .articleSubcategory_ {
    gap: 10px;
  }

  .schedulecontent_ {
    padding: 20px 0;
  }

  /* Upload Article Start */
  .upload_Articles_Btn .signUpBtn {
    padding: 7px 19px;
    font-size: 14px;
  }

  .upload_Articles_Btn .signUpBtn.me-3.mt-5 {
    margin-top: 15px !important;
  }

  .upload_Articles_Btn .secondaryBtb_ {
    padding: 7px 12px;
    font-size: 14px;
  }

  .upload_Articles_Btn .secondaryBtb_.mt-5 {
    margin-top: 8px !important;
  }

  .uploadImgeboxNewArticle .uploadfileIcon {
    margin-left: 0;
    max-width: 245px;
  }

  .allarticle__ .rejectTkt {
    margin: 5px 0 0;
    font-size: 11px;
  }
  /* Upload Article End */
  /* Home Start */
  .time_Saved {
    margin-bottom: 0;
  }

  .growth_box {
    margin-bottom: 0;
  }

  .slider_box.change {
    margin: 30px 10px 0;
  }

  .testimonial {
    padding: 0;
  }

  .subscribingSection {
    padding: 40px 0;
  }

  /* Home page End */
  .selectbox_ {
    padding: 12px 16px;
    font-size: 14px;
  }

  .authLoginSec .authOuterSec {
    margin-top: 16px;
    padding-bottom: 16px;
  }

  /* .phoneControl {
    width: 75%;
  } */

  /* .phoneControl .react-tel-input .flag-dropdown {
    left: -32%;
  } */

  .authPara {
    font-size: 14px;
    margin-top: 10px !important;
  }

  .article_AuthPara {
    font-size: 16px;
    margin-top: 0 !important;
  }

  .authheading {
    font-size: 35px;
  }

  .faqpage_parent {
    padding: 30px;
  }

  #deletCategoryModal .modal-header,
  #paymentModal .modal-header {
    /* padding: 18px !important; */
  }

  .modal_Heading {
    font-size: 20px;
    text-align: center;
  }

  #scheduleModal {
    max-width: 400px;
  }

  /* .scheduleForm .phoneControl {
    width: 79% !important;
  } */

  /* .scheduleForm .phoneControl .react-tel-input .flag-dropdown {
    left: -26% !important;
  } */

  .scheduleDemoSection .secondry_btn {
    font-size: 15px;
    padding: 12px 24px;
    margin-right: 16px;
  }

  .scheduleDemoSection .signUpBtn {
    padding: 12px 24px;
    font-size: 15px;
  }

  .nameControl {
    font-size: 14px !important;
    padding: 12px 16px;
  }

  .nameControl::placeholder {
    font-size: 14px !important;
  }

  .phoneControl .react-tel-input .form-control {
    padding: 12px 24px !important;
    font-size: 15px !important;
  }

  .contactBox {
    max-width: 450px;
  }

  /* .contactBox .phoneControl {
    width: 75%;
  } */

  /* .contactBox .phoneControl .react-tel-input .flag-dropdown {
    left: -31%;
  } */

  .contactPage .footer {
    margin-top: 50px;
  }

  .contactBox .authheading {
    font-size: 32px;
  }

  .contactBox .authPara {
    font-size: 14px;
    margin-top: 5px !important;
  }

  .scheduleModalimg {
    width: 85px;
    height: 85px;
  }

  .scheduleModalSec .authheading {
    font-size: 35px;
  }

  .scheduleModalSec .modalPara {
    font-size: 16px;
  }

  .scheduleModalSec .signUpBtn {
    padding: 14px 30px;
    font-size: 15px;
  }

  .table_Content_Summary p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
  }

  .table_Content_Summary ::marker {
    font-size: 16px;
  }

  /* .mainSidebar.hide .brandLink {
    padding: 24px;
    height: 81px;
  } */

  .brandLink {
    /* border: none; */
  }

  .content-wrapper {
    margin-right: 0;
  }

  .publishSubInner {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .publishRight {
    padding-left: 0;
  }

  .publishLeft {
    border-right: none;
    padding-right: 0;
  }

  .stateText {
    font-size: 15px;
    line-height: normal;
    width: 120px !important;
  }

  .dashArticleSub {
    padding: 20px;
  }

  .uploadHeading {
    font-size: 18px;
  }

  .DashArrow {
    width: 45px;
    height: 45px;
  }

  .profileText {
    font-size: 18px;
  }

  /* Start Article */
  .articleInfo_ .stateText {
    font-size: 16px;
  }

  .stateTextpara {
    font-size: 12px;
  }

  .allarticle__ .articlependingTkt {
    padding: 6px 2px;
    font-size: 11px;
    margin: 5px 0 0;
  }

  .article_Review .pendingTkt {
    padding: 4px 5px;
    font-size: 11px;
  }

  /* .dashArticle .innerHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  } */

  .innerHeadText {
    font-size: 20px;
  }

  .categoryText {
    font-size: 14px;
  }

  .article_Review .pointCursor {
    height: 15px;
  }

  .contentPreview_ ol {
    padding: 15px !important;
  }

  /* End Article */

  /* Start Dashboard */
  .dashArticle .DashArrow img {
    width: 17px;
    height: 17px;
  }

  .DashArrow {
    width: 25px;
    height: 25px;
  }

  .dashArticleSub {
    padding: 30px;
  }

  .addPublishLeft .searchBox {
    margin: 20px 0 0 !important;
  }

  /* End Dashboard */
  /* Start Media */
  .publicationInfo {
    margin-top: 15px;
  }

  /* End Media */
  /* Start Ticket */
  .raiseTicket_Btn_Parent {
    margin-top: 20px;
  }

  .raiseTicket_Btn_Parent .signUpBtn {
    padding: 13px 12px;
    font-size: 14px;
    display: inline !important;
  }

  .raise_Ticket_Btn {
    padding: 10px 15px;
    font-size: 14px;
    display: inline !important;
  }

  .secondaryBtb_ {
    padding: 13px 12px;
    font-size: 14px;
  }

  .upgradeSubcription_Content .signUpBtn.upgrade_Subcription_Btn {
    padding: 16px 18px;
    font-size: 14px;
  }

  /* End Ticket */
  /* Start Profile */
  .profileSec .profileRight {
    flex-direction: column;
  }

  .profileSec .profileInner {
    width: 100%;
  }

  .profileSec .profile_Heading {
    font-size: 24px;
  }

  .profileSec .DashArrow img {
    height: 20px;
    width: 20px;
  }

  .profileSec .profileRight .DashArrow {
    width: 26px;
    height: 25px;
  }

  /* End Profile */

  .noFoundText {
    font-size: 16px;
    /* text-align: start !important; */
  }

  .flexBox {
    flex-wrap: wrap;
  }

  .dashboard_Section .flexBox {
    flex-wrap: nowrap;
  }

  .mainSidebar.hide .brandLink {
    padding: 20px 35px 15px 20px;
  }

  /* start Subscription */
  .current_Plan_Parent {
    margin-bottom: 20px;
  }

  .includes_Parent {
    margin-top: 20px;
  }

  /* End Subscription */
  /* .content-wrapper {
    height: 100%;
  } */

  .subscriptionBanner_ .planInnerHead {
    padding-top: 20px;
  }
  #cardPaymentModal .modal-body {
    padding: 30px 30px;
  }
  .addNewCard_Btn {
    font-size: 12px;
    padding: 9px 10px;
    border-radius: 30px;
    margin-top: 15px;
    min-width: 118px;
  }
  .addNewCard_Btn.mb-3 {
    margin-bottom: 0;
  }

  /* Notification Modal */
  #notificationModal.modal-dialog {
    right: 0;
    max-width: 360px;
  }
}

@media screen and (max-width: 475px) {
  .authOtpSec input {
    width: 65px !important;
    height: 65px;
    font-size: 18px;
  }

  /* .phoneControl {
    width: 70%;
  } */

  /* .phoneControl .react-tel-input .flag-dropdown {
    left: -41%;
  } */

  .faqpage_parent {
    padding: 18px;
  }

  /* .contactBox .phoneControl {
    width: 70%;
  } */

  /* .contactBox .phoneControl .react-tel-input .flag-dropdown {
    left: -40%;
  } */

  .contactBox {
    max-width: 380px;
    padding: 24px 16px;
  }

  #scheduleModal {
    max-width: 370px;
  }

  /* .scheduleForm .phoneControl {
    width: 76% !important;
  } */

  /* .scheduleForm .phoneControl .react-tel-input .flag-dropdown {
    left: -30% !important;
  } */

  .content-wrapper {
    padding: 12px;
  }

  .clearBtn {
    font-size: 14px;
  }

  .publicationInfo {
    padding: 12px;
    gap: 8px;
  }
}

@media screen and (max-width: 425px) {
  .articleimageinfo_ {
    margin-right: 12px !important;
  }
  .articlependingTkt {
    max-width: 98px;
  }
  .stateText {
    font-size: 14px;
    width: 67px !important;
  }
  .sidebarOuter {
    height: calc(100vh - 190px);
  }

  .articles_SubCategory {
    gap: 15px;
  }

  .authheading {
    font-size: 32px;
  }

  .authPara br {
    display: none;
  }

  .authOtpSec input {
    width: 60px !important;
    height: 60px;
    font-size: 18px;
  }

  .linkSub {
    font-size: 14px;
  }

  .form-group.checkbx label {
    font-size: 13px;
  }

  .authInnerSec {
    padding: 24px 14px;
  }

  .authInnerSec {
    margin: 18px;
  }

  .contactBox {
    max-width: 350px;
    padding: 20px 12px;
  }

  #scheduleModal {
    max-width: 340px;
  }

  /* .scheduleForm .phoneControl {
    width: 71% !important;
  } */

  /* .scheduleForm .phoneControl .react-tel-input .flag-dropdown {
    left: -38% !important;
  } */

  /* .content-wrapper {
    height: calc(100vh - 138px);
  } */

  /* .mainSidebar.hide {
    width: 70px;
  } */

  /* .dashboardMain {
    width: calc(100vw - 70px);
    margin-left: 70px;
  } */

  .ToggleIcon img {
    width: 16px;
    height: 16px;
  }

  .sideHeading {
    font-size: 14px;
    margin-left: 0;
  }

  .sideListHead {
    margin: 0;
  }

  /* .mainSidebar.hide .brandLink {
    height: 40px;
  } */

  .mainSidebar.hide .sidebarOuter {
    height: calc(100vh - 80px);
  }

  /* .DashArrow {
    width: 40px;
    height: 40px;
  }

  .DashArrow img {
    width: 28px;
    height: 28px;
  } */

  .uploadHeading {
    font-size: 16px;
  }
  .aiAddOn_Statetxt{
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  /* .articleimageinfo_ {
    height: 150px;
  } */
  /* .stateTextpara {
    height: 40px;
    overflow: hidden;
  } */
  .state_Txt {
    width: 260px !important;
  }
}

@media screen and (max-width: 1200px) {
  .aboutStory_Quates {
    display: none;
  }
  .ourStory_Box {
    padding: 30px;
  }
  .ourStory_Para {
    padding: 0;
  }
  .ourVision_Para br {
    display: none;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .publicationInfo {
    flex-wrap: wrap;
  }
  .publishInner {
    width: 100%;
  }
  .publishSubInner {
    justify-content: space-between;
  }
}
