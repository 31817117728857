.viewBtn {
  border: 1px solid #ffffff;
  padding: 6px 12px;
  background-color: transparent;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #ffffff;
}
.drafTable {
  margin: 24px 0;
  max-height: calc(85vh - 245px);
  overflow-y: scroll;
}
.drafTable .recentSubHead:first-child {
  min-width: 130px;
}
.drafTable .recentSubHead:nth-child(2) {
  min-width: 120px;
}
.drafTable .recentSubHead:nth-child(3) {
  min-width: 140px;
}

.drafTable .recentSubHead:last-child {
  width: 350px;
  min-width: 350px;
}
.readText {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
}
.ckView {
  color: #ffffff;
}
.ckView img {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
  margin-bottom: 10px;
}
#searchArticleModal {
  width: 100%;
  max-width: 950px;
}
.artcleContent {
  background: #131a1c;
  box-shadow: 0px 4px 12px 0px #0000000f;
  border-radius: 30px;
  padding: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  height: 35vh;
  overflow-y: scroll;
}
.cancelBtn {
  padding: 14px 32px;
  border-radius: 30px;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #ffffff !important;
  border: 2px solid #ff5c5d;
}
.articleBtn {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.articleBtn .cancelBtn,
.articleBtn .signUpBtn {
  width: 100%;
  max-width: 200px;
}
#searchArticleModal .modal-header {
  padding: 30px !important;
}
.artcleContent::-webkit-scrollbar {
  display: none;
}
.articleRadio [type="radio"]:checked,
.articleRadio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.articleRadio [type="radio"]:checked + label,
.articleRadio [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
.articleRadio [type="radio"]:checked + label:before,
.articleRadio [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #ff5c5d;
  border-radius: 100%;
  background: transparent;
}
.articleRadio [type="radio"]:checked + label:after,
.articleRadio [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #ff5c5d;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.articleRadio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.articleRadio[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.labelTxt {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

.articleRadio {
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 24px 0;
}
.articleRadio [type="radio"]:checked + .labelTxt {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}
.articleRadio [type="radio"]:not(:checked) + .labelTxt {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}
.articleSelect .selectbox_ {
  padding: 12px 24px;
}
/* .scheduleForm .phoneControl {
  width: 84%;
} */
/* .scheduleForm .phoneControl .react-tel-input .flag-dropdown {
  left: -18%;
} */

.autocomplete-list {
  background: transparent;
  border-radius: 12px;
  padding: 10px;
  list-style: none;
  box-shadow: 1px 1px 4px 2px #eee;
  margin-top: 2px;
}

.autocomplete-item {
  font-size: 16px;
  padding-bottom: 5px;
  color: #fff;
}

.uploadArticle_UploadArticleImage {
  width: 150px;
  height: 150px;
  min-width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploadArticle_ImgBox {
  gap: 30px;
}

.uploadImgeboxNewArticle.uploadArticle_ImgBox {
  flex-wrap: wrap;
  padding: 40px 30px 70px;
  height: 100%;
}
.addMore_ImgBtn {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: max-content;
  bottom: 15px;
  padding: 8px 22px;
  cursor: pointer;
}
.client_Logoes img {
  border-radius: 10px;
  height: 267px !important;
}

/* Article Page Css */

.boxes_new_section .main_box_artical {
  background: #131a1c;
  padding: 10px 16px 10px 10px;
  color: #fff;
  border-radius: 20px;
  margin: 0px;
}
.boxes_new_section .box_img {
  max-width: 110px;
  border-radius: 20px;
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxes_new_section .box_img img {
  width: 100%;
  border-radius: 20px;
  max-height: 100%;
  object-fit: contain;
  border-radius: 12px;
}
.boxes_new_section .box_content_area {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.boxes_new_section .box_heading_button {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 15px; */
}
.boxes_new_section .artical_heading {
  font-size: 20px;
  width: 50%;
  /* height: 30px;  */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}
.boxes_new_section .artical_button {
  color: #fff;
  background: #ffbc49;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px 14px;
  border: none;
  border-radius: 10px;
  width: fit-content;
  height: fit-content;
  margin-left: 10px;
  font-size: 12px;
}
.boxes_new_section .box_date {
  text-align: end;
  width: 100%;
  display: inline-block;
  margin-top: auto;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
}
.boxes_new_section .box_para {
  /* white-space: nowrap; */
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  color: #9d9d9d;
  height: 54px;
}
.boxes_new_section .box_para h1,
.boxes_new_section .box_para h2,
.boxes_new_section .box_para h3,
.boxes_new_section .box_para h4,
.boxes_new_section .box_para h5,
.boxes_new_section .box_para h6,
.boxes_new_section .box_para p,
.boxes_new_section .box_para strong {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  color: #9d9d9d;
}
.row.main_box_artical {
  margin-top: 14px;
}
